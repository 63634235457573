import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import './index.scss';
import ServiceGateway from '../../../../../services/ServiceGateway';
import { useKwsState } from '../../../../../contexts/KwsStateContext/KwsStateContext';
import { isDesktopOrTablet } from '../../../../../utils/helper';
import { isProd } from '../../../../../utils/environment';
import { ConfirmationOrErrorModel } from '../../../../../types/ConfirmationErrorModel';
import ConfirmationModal from '../../../CommonSections/ConfirmationModal';

export interface FinApiContainerProps {
  finApiToken: string;
  onSuccess: (processingTime: number) => void;
  renewFinApiProcess: () => void;
}

declare const FinApiWebForm: any;

const FinApiContainer = ({ finApiToken, onSuccess, renewFinApiProcess }: FinApiContainerProps) => {
  const { kwsState } = useKwsState();
  const [ibanEqualError, setIbanEqualError] = useState<ConfirmationOrErrorModel>();

  const handleIbanError = () => {
    renewFinApiProcess();
    setIbanEqualError(undefined);
  };

  const createWebForm = () => {
    const finApiContainer = document.querySelector('#webFormContainer');

    const handleComplete = async () => {
      try {
        const fetchDacResult = await ServiceGateway.fetchFinApiDac(kwsState!.id);
        onSuccess(fetchDacResult.data.processingTime);
      } catch (error: any) {
        if (error?.request?.status === 400) {
          const data = error?.response?.data;
          if (data.type === 'IBAN_EQUALITY') {
            // we just want to show if the error is IBAN_EQUALITY error
            setIbanEqualError({
              title: data.title,
              message: data.message,
            });
          }
        }
      }
    };
    const handleFail = () => {
      renewFinApiProcess();
    };
    const handleAbort = () => {
      renewFinApiProcess();
    };
    const handleLoadError = () => {
      Sentry.captureMessage('FinApiWebForm load error');
    };

    FinApiWebForm?.load(
      finApiContainer,
      {
        token: finApiToken,
        language: 'de',
        targetEnvironment: isProd() ? 'live' : 'sandbox',
        layoutConfig: isDesktopOrTablet() ? 'sm' : 'xs',
        noMargins: 'true',
      },
      {
        onComplete: handleComplete,
        onFail: handleFail,
        onAbort: handleAbort,
        onLoadError: handleLoadError,
      },
      document,
    );
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://webform-dist.finapi.io/latest/dist/web-form.min.js';
    script.addEventListener('load', () => {
      if (finApiToken) {
        createWebForm();
      }
    });
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className='finapi-container'>
        <div id='webFormContainer' />
      </div>

      {ibanEqualError && (
        <ConfirmationModal
          onCancel={handleIbanError}
          onSubmit={handleIbanError}
          submitLabel='zurück zum Login'
        >
          <div className='finapi-iban-equal-modal'>
            <p>{ibanEqualError.title}</p>
            <p>{ibanEqualError.message}</p>
          </div>
        </ConfirmationModal>
      )}
    </>
  );
};

export default FinApiContainer;
