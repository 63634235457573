import React, { useEffect, useState } from 'react';
import './index.scss';

interface SpinnerProps {
  delay?: number;
  text?: string;
}

const SpinnerFullPage = ({ delay, text }: SpinnerProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        setShow(true);
      }, delay);
    } else {
      setShow(true);
    }
  }, []);

  return show ? (
    <div className='full-page-spinner' data-testid='full-page-spinner'>
      <div className='spinner' />
      {text ? <div className='text'>{text}</div> : null}
    </div>
  ) : null;
};

export default SpinnerFullPage;
