import React, { useState } from 'react';
import './index.scss';
import kontoumzug from '../../../../assets/landing/kontoumzug.png';
import guarantee from '../../../../assets/landing/guarantee.png';
import protection from '../../../../assets/landing/protection.png';
import tuv from '../../../../assets/landing/tuv.png';
import checkIcon from '../../../../assets/landing/check-icon.png';
import Button from '../../../Common/Button';
import { OutCome } from '../..';
import TooltipWithImg from '../../../Common/TooltipWithImg';
import RedirectionModal from './RedirectionModal';
import { DeviceOutputEnum } from '../../../../utils/deviceOutput';

interface KontoumzugSectionProps {
  outCome: OutCome;
}

const isDesktop = window.wf_deviceoutput === DeviceOutputEnum.DESKTOP;

const KontoumzugSection = ({ outCome }: KontoumzugSectionProps) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className='kontoumzug-section' data-testid='kontoumzug-section'>
      <div className='wrapper'>
        <div className='col-1'>
          <h2>CHECK24 Kontoumzug:</h2>
          <h2>Kostenlos, bequem und ohne Papierkram</h2>
          <p className='text'>
            Jetzt mit einem Klick alles Wichtige auf Ihr neues Girokonto umziehen.
          </p>
          <div className='row-1'>
            <img className='kontoumzug-img' src={kontoumzug} alt='' />
            <div className='list'>
              <div className='list-item'>
                <img src={checkIcon} alt='' />
                <p>Zahlungspartner über neue IBAN informieren</p>
              </div>
              <div className='list-item'>
                <img src={checkIcon} alt='' />
                <p>Daueraufträge auf neues Konto umziehen</p>
              </div>
              <div className='list-item'>
                <img src={checkIcon} alt='' />
                <p>Altes Konto zum Wunschtermin schließen</p>
              </div>
            </div>
          </div>
          {outCome === 'default' ? (
            <Button
              onClick={() => {
                setShowModal(true);
              }}
              label='Kontoumzug starten'
              data-testid='show-modal'
            />
          ) : null}
          {outCome === 'default' || outCome === 'teaser' ? (
            <>
              <p className='text'>Sie haben noch kein neues Girokonto bei CHECK24 abgeschlossen?</p>
              <Button
                classNames='alternative'
                onClick={() => {
                  window.open(
                    isDesktop
                      ? 'https://www.check24.de/girokonto/'
                      : 'https://m.check24.de/girokonto/',
                    isDesktop ? '_blank' : '_self',
                  );
                }}
                label='zum Girokonto-Vergleich'
              />
            </>
          ) : null}
        </div>

        <div className='col-2'>
          <div className='row-1'>
            <img className='kontoumzug-img' src={kontoumzug} alt='' />
          </div>
          <div className='row-2'>
            <TooltipWithImg image={guarantee}>
              <p>
                <b>100% Sorglos-Garantie</b> Mit unserer kostenlosen CHECK24 Kontoumzug
                Sorglos-Garantie versprechen wir Ihnen einen reibungslosen Girokonto-Umzug. Sollten
                Ihnen im Rahmen des Girokonto-Umzugs Gebühren entstehen (zum Beispiel aufgrund einer
                Lastschriftrückbuchung oder Mahnung), so übernimmt CHECK24 diese Kosten für Sie. Des
                Weiteren empfehlen wir Ihnen, beide Girokonten 3 Monate lang parallel laufen zu
                lassen (inklusive ausreichend Guthaben auf beiden Konten). Auf Wunsch übernimmt
                CHECK24 daher bis zu 3 Monate die Kontoführungsgebühren für Ihr altes Girokonto.{' '}
                <a
                  href='https://check24.de/girokonto/funktioniert-garantiert-bedingungen/'
                  target='_blank'
                  rel='noreferrer'
                >
                  Details
                </a>
              </p>
            </TooltipWithImg>
            <TooltipWithImg image={protection}>
              <p>
                <b>Datenschutzgarantie</b> Die Anmeldung in Ihrem alten Konto erfolgt verschlüsselt
                und wurde vom TÜV-Süd zertifiziert. Ihre Log-In Daten werden von CHECK24 nicht
                gespeichert.
              </p>
            </TooltipWithImg>
            <img src={tuv} alt='' />
          </div>
        </div>
      </div>
      {showModal && <RedirectionModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default KontoumzugSection;
