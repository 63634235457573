import React from 'react';
import './index.scss';
import { ReactComponent as PlusIcon } from '../../../assets/plus.svg';

interface AddButtonProps {
  children?: React.ReactNode;
  title: string;
  onClick: () => void;
}

const AddButton = ({ children, title, onClick }: AddButtonProps) => (
  <button
    type='button'
    className='add-button'
    onClick={onClick}
    tabIndex={0}
    data-testid='add-button-id'
  >
    <div className='icon'>{children ?? <PlusIcon />}</div>
    <div>{title}</div>
  </button>
);

export default AddButton;
