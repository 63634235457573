import React, { useState } from 'react';
import './index.scss';

import { ReactComponent as CarretIcon } from '../../../../../assets/carret.svg';

interface EntryProps {
  number: number;
  title: string;
  description: string;
  list: (string | JSX.Element)[] | string[];
  image?: string;
}

const Entry = ({ number, title, description, list, image }: EntryProps) => {
  const [showContent, setShowContent] = useState(false);
  return (
    <div className='landing-entry' data-testid='landing-entry'>
      <div className='title'>
        <div className='number'>{number}</div>
        <p className='text'>{title}</p>
      </div>
      <div className='description'>
        {description}{' '}
        {!showContent ? (
          <span className='detail' onClick={() => setShowContent(!showContent)}>
            mehr <CarretIcon className='carret' />
          </span>
        ) : null}
      </div>
      {showContent ? (
        <>
          <div className='entry-list'>
            <ul>
              {list.map((l, i) => (
                <li key={i}>
                  <span>{l}</span>
                </li>
              ))}
            </ul>
          </div>
          {image ? (
            <div className='image'>
              <img src={image} alt='' />
            </div>
          ) : null}
          <div className='closing-tag'>
            <span onClick={() => setShowContent(!showContent)}>
              weniger <CarretIcon className='carret' />
            </span>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Entry;
