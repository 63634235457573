import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import ServiceGateway from '../../../services/ServiceGateway';
import './index.scss';
import TextInput from '../../Common/TextInput';
import SelectInput from '../../Common/SelectInput';
import { parseUserInputDate } from '../../../utils/helper';
import { EntryStateEnum } from '../index';
import Checkbox from '../../Common/Checkbox';
import NumberInput from '../../Common/NumberInput';
import Button from '../../Common/Button';
import IbanInput from '../../Common/IbanInput';

interface TestState {
  ibanSource: string;
  ibanTarget: string;
  birthdate: string;
  email: string;
  invalidSwitchingDates: boolean;
  testcase: string;
  fintecDac: string;
  fintecStandingOrders: string;
  overviewData: string;
  ssoUserId: string;
  mobileNumber: string;
  bankId: string;
  partner: string;
}

const initialState: TestState = {
  birthdate: '22.02.1979',
  email: 'philipp.knuechel@check24.de',
  invalidSwitchingDates: false,
  testcase: 'UNATTACHED',
  ibanSource: 'DE62888888880012345678',
  ibanTarget: 'DE59760200700019367282',
  bankId: '1',
  fintecDac: '',
  fintecStandingOrders: '',
  overviewData: '',
  ssoUserId: '',
  mobileNumber: '',
  partner: 'TEST',
};

interface TestProps {
  updateState: (state: EntryStateEnum) => void;
}

const Test = ({ updateState }: TestProps) => {
  const [input, setInput] = useState(initialState);
  const [c24token, setC24Token] = useState('validtoken');
  const [isLoading, setIsLoading] = useState(false);

  const fetchInitialState = () => {
    const testCaseFromStorage = window.localStorage.getItem('testcase');
    const testcase: TestState = testCaseFromStorage && JSON.parse(testCaseFromStorage);
    setInput({ ...initialState, ...testcase });
  };

  useEffect(() => {
    fetchInitialState();
  }, []);

  const setInitialState = (testcase: TestState) => {
    window.localStorage.setItem('testcase', JSON.stringify(testcase));
  };

  const submit = () => {
    setIsLoading(true);
    const { birthdate, ...rest } = input;
    const date = parseUserInputDate(birthdate).format('YYYY-MM-DD');
    ServiceGateway.startTestCase({
      ...rest,
      birthdate: date,
    })
      .then((r) => {
        setInitialState(input);
        const url = new URL(window.location.href);
        url.searchParams.append('id', r.data.processId);

        if (input.partner === 'CH24_BANK') {
          Cookies.set('c24bank-session', c24token, {
            path: '/',
          });
        }

        window.location.href = url.href;
      })
      .catch(() => {
        updateState(EntryStateEnum.NOT_POSSIBLE);
        setIsLoading(false);
      });
  };

  return (
    <div className='test-page'>
      <h2>Wähle deinen Testcase</h2>

      <div className='input-row'>
        <IbanInput
          placeholder='IBAN Source'
          value={input.ibanSource}
          onChange={(v) => setInput({ ...input, ibanSource: v })}
        />
      </div>
      <div className='input-row'>
        <IbanInput
          placeholder='IBAN Target'
          value={input.ibanTarget}
          onChange={(v) => setInput({ ...input, ibanTarget: v })}
        />
      </div>
      <div className='input-row'>
        <SelectInput
          placeholder='Bank Target'
          value={input.bankId}
          options={[
            { value: '1', placeholder: 'HypoVereinsbank' },
            { value: '2', placeholder: '1822' },
            { value: '3', placeholder: 'PSD Bank Nürnberg' },
            { value: '4', placeholder: 'norisbank' },
            { value: '5', placeholder: 'Postbank' },
            { value: '6', placeholder: 'Tomorrow' },
            { value: '7', placeholder: 'DKB - Dt. Kreditbank' },
            { value: '8', placeholder: 'C24 Bank' },
            { value: '9', placeholder: 'ING' },
            { value: '10', placeholder: 'Western Union' },
            { value: '11', placeholder: 'bunq' },
            { value: '12', placeholder: 'Deutsche Bank' },
            { value: '13', placeholder: 'Revolut' },
            { value: '14', placeholder: 'Santander' },
          ]}
          onChange={(v) => setInput({ ...input, bankId: v })}
        />
      </div>
      <div className='input-row'>
        <TextInput
          placeholder='E-Mail'
          value={input.email}
          onChange={(v) => setInput({ ...input, email: v })}
        />
      </div>
      <div className='input-row'>
        <TextInput
          placeholder='Geburtsdatum (TT.MM.YYYY)'
          value={input.birthdate}
          onChange={(v) => setInput({ ...input, birthdate: v })}
        />
      </div>
      <div className='input-row checkbox'>
        <p className='label'>invalidSwitchingDates</p>
        <Checkbox
          inputId='check_invalidSwitchingDates'
          checked={input.invalidSwitchingDates}
          onChange={(v) => setInput({ ...input, invalidSwitchingDates: v })}
        />
        <hr />
      </div>
      <div className='input-row'>
        <SelectInput
          placeholder='Page'
          onChange={(v) => setInput({ ...input, testcase: v })}
          value={input.testcase}
          options={[
            { value: 'UNATTACHED', placeholder: 'UNATTACHED' },
            { value: 'OVERVIEW', placeholder: 'OVERVIEW' },
            { value: 'SIGNATURE', placeholder: 'SIGNATURE' },
            {
              value: 'WAITING_FOR_IBAN_BANK',
              placeholder: 'WAITING_FOR_IBAN_BANK',
            },
            {
              value: 'WAITING_FOR_IBAN_CUSTOMER',
              placeholder: 'WAITING_FOR_IBAN_CUSTOMER',
            },
            { value: 'THANKS', placeholder: 'THANKS' },
          ]}
        />
      </div>
      <div className='input-row'>
        <TextInput
          placeholder='Fintec DAC'
          value={input.fintecDac}
          onChange={(v) => setInput({ ...input, fintecDac: v })}
        />
      </div>
      <div className='input-row'>
        <TextInput
          placeholder='Fintec StandingOrders'
          value={input.fintecStandingOrders}
          onChange={(v) => setInput({ ...input, fintecStandingOrders: v })}
        />
      </div>
      <div className='input-row'>
        <TextInput
          placeholder='Overview Test Data'
          value={input.overviewData}
          onChange={(v) => setInput({ ...input, overviewData: v })}
        />
      </div>
      <div className='input-row'>
        <TextInput
          placeholder='SSO User ID'
          value={input.ssoUserId}
          onChange={(v) => setInput({ ...input, ssoUserId: v })}
        />
      </div>
      <div className='input-row'>
        <NumberInput
          placeholder='Mobile Number'
          value={input.mobileNumber}
          onChange={(v) => setInput({ ...input, mobileNumber: v })}
        />
      </div>
      <div className='input-row'>
        <SelectInput
          placeholder='Partner'
          onChange={(v) => setInput({ ...input, partner: v })}
          value={input.partner}
          options={[
            { value: 'CH24_BANK', placeholder: 'CH24 BANK' },
            { value: 'GIRO', placeholder: 'GIRO' },
            { value: 'TEST', placeholder: 'TEST' },
          ]}
        />
      </div>
      {input.partner === 'CH24_BANK' && (
        <div className='input-row'>
          <TextInput placeholder='C24 Token' value={c24token} onChange={setC24Token} />
        </div>
      )}
      <div className='input-row'>
        <Button onClick={submit} label='START!' loading={isLoading} />
      </div>
    </div>
  );
};

export default Test;
