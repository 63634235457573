import React, { useEffect, useState } from 'react';
import { useKwsState } from '../../../../contexts/KwsStateContext/KwsStateContext';
import ServiceGateway from '../../../../services/ServiceGateway';
import Intro from '../../CommonSections/Intro';
import { isDesktopOrTablet, scrollToPosition } from '../../../../utils/helper';
import { StandingOrderDetailsModel } from '../../../../types/Overview/StandingOrderModel';
import SpinnerFullPage from '../../../Common/SpinnerFullPage';
import StandingOrderDetailTab from './components/StandingOrderDetailTab';
import AllTransactionsTab from '../../CommonSections/AllTransactionsTab';
import TabButtons from '../../../Common/TabButtons';
import ResetButton from '../../../Common/ResetButton';
import Container from '../../../Common/Container';

const StandingOrderDetails = () => {
  const { kwsState, standingOrderToProcess, setStandingOrderToProcess, setSwitchingDateToProcess } =
    useKwsState();
  const { pageType, id: soId } = standingOrderToProcess!;
  const [standingOrderDetailState, setStandingOrderDetailState] =
    useState<StandingOrderDetailsModel>();

  const getStandingOrder = async () => {
    switch (pageType) {
      case 'EDIT': {
        const response = await ServiceGateway.getStandingOrder(kwsState!.id, soId!);
        setStandingOrderDetailState(response.data);
        break;
      }
      case 'ADD': {
        const response = await ServiceGateway.preCreateStandingOrder(kwsState!.id);
        setStandingOrderDetailState(response.data);
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    scrollToPosition('0');
    getStandingOrder();
  }, []);

  const goBack = () => {
    setStandingOrderToProcess(undefined);
    setSwitchingDateToProcess(false);
  };

  if (!standingOrderDetailState) {
    return <SpinnerFullPage />;
  }

  const tabData = [
    {
      id: 0,
      title: 'Dauerauftrag',
      content: (
        <StandingOrderDetailTab
          standingOrderDetailState={standingOrderDetailState}
          setStandingOrderDetailState={setStandingOrderDetailState}
        />
      ),
    },
    ...(standingOrderDetailState.transactions && standingOrderDetailState.transactions?.length !== 0
      ? [
          {
            id: 1,
            title: 'Transaktionen',
            content: <AllTransactionsTab transactions={standingOrderDetailState!.transactions!} />,
          },
        ]
      : []),
  ];

  return (
    <div className='standing-orders-details' data-testid='standing-orders-details'>
      <ResetButton title='zurück zur Übersicht' onClick={goBack} isSticky={!isDesktopOrTablet()} />

      {/* Item Info */}
      {pageType === 'EDIT' && (
        <Intro
          data={standingOrderDetailState}
          pageType='standingOrder'
          classNames={tabData.length > 1 ? 'bottom-border' : ''}
        />
      )}

      {pageType === 'ADD' && (
        <Container classNames='mobile-shadow equal-padding'>
          <h2>Dauerauftrag einrichten</h2>
        </Container>
      )}

      {tabData.length > 1 ? (
        <TabButtons
          tabs={tabData}
          classNames={isDesktopOrTablet() ? 'sticky' : 'sticky-with-button-mobile'}
        />
      ) : (
        tabData[0].content
      )}
    </div>
  );
};

export default StandingOrderDetails;
