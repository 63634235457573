import React, { useEffect, useRef, useState } from 'react';
import PinField from 'react-pin-field';
import { useSearchParams } from 'react-router-dom';
import ServiceGateway from '../../../services/ServiceGateway';
import './index.scss';
import Container from '../../Common/Container';
import ContactCard from '../../Common/ContactCard';
import Button from '../../Common/Button';
import { ReactComponent as PinIcon } from '../../../assets/pin.svg';

interface MTanCheckProps {
  mobileNumber: string;
  updateState: () => void;
}

const MTanCheck = ({ mobileNumber, updateState }: MTanCheckProps) => {
  const pinField = useRef<HTMLInputElement[]>(null);
  const smsToken = useRef();
  const [pin, setPin] = useState('');
  const [message, setMessage] = useState('');
  const [showResendMessage, setShowResendMessage] = useState(false);
  const [wrongPin, setWrongPin] = useState(false);

  const [searchParams] = useSearchParams();
  const kwsProcessId = searchParams.get('id');

  const clearPinField = () => {
    if (pin) {
      setPin('');
      // eslint-disable-next-line
      pinField.current?.forEach((input) => (input.value = ''));
    }
  };

  const submit = () => {
    setShowResendMessage(false);
    if (smsToken.current) {
      ServiceGateway.verifySms(kwsProcessId!, smsToken.current, pin).then((result) => {
        if (result.data.passVerification) {
          updateState();
        } else {
          if (pinField.current?.[0]) {
            pinField.current?.[0].focus();
          }
          clearPinField();
          setWrongPin(true);
          setMessage(result.data.error?.message || '');
        }
      });
    }
  };

  const sendSms = async (): Promise<boolean> =>
    ServiceGateway.sendSmsCode(kwsProcessId!).then((result) => {
      if (result.data.token) {
        smsToken.current = result.data.token;
        return true;
      }
      setMessage(result.data.error?.message || '');
      return false;
    });

  const reSendSms = async () => {
    setShowResendMessage(false);
    clearPinField();
    setWrongPin(false);
    const sendSmsRes: boolean = await sendSms();
    if (sendSmsRes) {
      setMessage('');
      setShowResendMessage(true);
    }
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13 && pin && pin.length === 6) {
      submit();
    }
  };

  const handleChange = (pinCode: string) => {
    if (showResendMessage) {
      setShowResendMessage(false);
    }
    if (wrongPin) {
      setWrongPin(false);
    }
    setPin(pinCode);
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  useEffect(() => {
    sendSms();
  }, []);

  return (
    <div className='mTan-check'>
      <Container>
        <div className='inner-wrapper'>
          <p className='title'>Zwei-Faktor-Authentifizierung</p>
          <div className='icon'>
            <PinIcon className='sms-pin' />
          </div>
          <p className='description'>
            Wir haben einen Code an <span className='phone-number'>{mobileNumber}</span> gesendet.
          </p>
          <p className='pin-title'>Code aus SMS</p>
          {/* <div className='pin-field-container'> */}
          <div className={`pin-field-container ${wrongPin && 'error'}`}>
            <PinField
              className='pin-field'
              length={6}
              onChange={handleChange}
              autoFocus
              validate='0123456789'
              inputMode='numeric'
              ref={pinField}
            />
          </div>
          {message && <p className='message'>{message}</p>}
          <div className='resend-sms' onClick={reSendSms}>
            Code erneut senden
          </div>
          {showResendMessage && (
            <p className='resend-message'>
              Ein neuer Code wurde gesendet. Bitte schauen Sie in Ihre Nachrichten.
            </p>
          )}
          <Button
            onClick={submit}
            label='Weiter'
            isDisabled={!pin || pin.length !== 6}
            //   loading={loading}
          />
        </div>
      </Container>
      <ContactCard />
    </div>
  );
};

export default MTanCheck;
