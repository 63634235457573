import React from 'react';
import StandingOrder from '../StandingOrder';
import { StandingOrderModel } from '../../../../../../../types/Overview/StandingOrderModel';

interface StandingOrderListItemProps {
  order: StandingOrderModel;
}

const StandingOrderListItem = ({ order }: StandingOrderListItemProps) => (
  <div className='standing-order-list-item' data-testid='standing-order-list-item'>
    <div className='alert-header'>
      <li key={order.id}>{order.recipientName} </li>
    </div>
    <div className='standing-order-detail'>
      <StandingOrder order={order} />
    </div>
  </div>
);

export default StandingOrderListItem;
