import React, { useCallback, useMemo, useState } from 'react';
import { FrontendRule } from '../../types/Overview/Overview';
import { FrontEndSection, Hint, HintTypes, IFrontendRuleStateProvider } from './FrontendRule.types';
import { FrontendRuleContext } from './FrontendRuleContext';

const FrontendRuleProvider: React.FC<Partial<IFrontendRuleStateProvider>> = ({
  frontendRule: frontendRuleMock,
  children,
}) => {
  const [frontendRule, setFrontendRule] = useState<FrontendRule | undefined>(frontendRuleMock);

  const decideSectionToShow = useCallback(
    (sectionName: FrontEndSection) => !frontendRule?.hideSections?.includes(sectionName),
    [frontendRule],
  );

  const decideHintToShow = useCallback(
    ({ type, hint, itemId }: Hint) =>
      !!frontendRule?.hints?.filter(
        (h) =>
          h.type === type &&
          h.hint === hint &&
          (type === HintTypes.NOTIFICATION ? h.itemId === itemId : true),
      )?.length,
    [frontendRule],
  );

  const initialProvider: IFrontendRuleStateProvider = useMemo(
    () => ({ frontendRule, setFrontendRule, decideHintToShow, decideSectionToShow }),
    [frontendRule, decideSectionToShow, decideHintToShow],
  );

  return (
    <FrontendRuleContext.Provider value={initialProvider}>{children}</FrontendRuleContext.Provider>
  );
};

export default FrontendRuleProvider;
