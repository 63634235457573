import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Entry } from '../Entry';
import NotPossible from '../NotPossible';
import Landing from '../Landing';
import KwsStateProvider from '../../contexts/KwsStateContext/KwsStateProvider';
import Restricted from '../Kws/Restricted';
import KwsBody from './KwsBodyWrapper';
import TooltipStateProvider from '../../contexts/TooltipStateContext/TooltipStateProvider';

const queryClient = new QueryClient();

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<Landing />} />
      <Route path='/start/landing' element={<Landing />} />
      <Route
        path='/start/app'
        element={
          <KwsBody>
            <QueryClientProvider client={queryClient}>
              <KwsStateProvider>
                <TooltipStateProvider>
                  <Entry />
                </TooltipStateProvider>
              </KwsStateProvider>
            </QueryClientProvider>
          </KwsBody>
        }
      />
      <Route
        path='/start/rejected-process'
        element={
          <KwsBody>
            <Restricted message='Ihr CHECK24 Kontoumzug konnte nicht gestartet werden, da Ihr neues Konto nicht eröffnet wurde.' />
          </KwsBody>
        }
      />
      <Route
        path='*'
        element={
          <KwsBody>
            <NotPossible />
          </KwsBody>
        }
      />
    </Routes>
  </BrowserRouter>
);

export default App;
