import React, { useState } from 'react';
import './index.scss';
import { ReactComponent as CarretIcon } from '../../../assets/carret.svg';
import { IntersectionObserverComponent } from '../../../hooks/useIntersectionObserver';

type ResetButtonProps = {
  onClick: () => void;
  title: string;
  isLoading?: boolean;
  isSticky?: boolean;
};

const ResetButton = ({ onClick, title, isLoading, isSticky: isStickyProp }: ResetButtonProps) => {
  const [isSticky, setIsSticky] = useState(false);
  const onIntersection = (isIntersecting: boolean) => {
    setIsSticky(isIntersecting);
  };

  return (
    <>
      {isStickyProp && <IntersectionObserverComponent onIntersection={onIntersection} />}
      <div
        onClick={onClick}
        className={`reset-button ${isSticky ? 'sticky' : ''}`}
        data-testid='reset-button'
      >
        <CarretIcon className='rotate-270' />
        {title}
        {isLoading && <div className='blue-spinner' data-testid='reset-spinner' />}
      </div>
    </>
  );
};

export default ResetButton;
