import React, { useState } from 'react';
import './index.scss';
import Container from '../../../../Common/Container';
import Tooltip from '../../../../Common/Tooltip';
import { TooltipStateEnum } from '../../../../../contexts/TooltipStateContext/Tooltip.types';
import { ValidationResult } from '../../../../../utils/validators';
import { useKwsState } from '../../../../../contexts/KwsStateContext/KwsStateContext';
import ServiceGateway from '../../../../../services/ServiceGateway';
import IbanInput from '../../../../Common/IbanInput';
import Button from '../../../../Common/Button';
import { EnvironmentEnum } from '../../../../../utils/environment';

const environmentLinkGenerator = (fileName: string) => {
  switch (process.env.REACT_APP_ENV) {
    case EnvironmentEnum.INTEGRATION:
      return `https://frontend.kws.check24-int.de/frontend/download/${fileName}`;
    case EnvironmentEnum.STAGING:
      return `https://frontend.kws.check24-test.de/frontend/download/${fileName}`;
    default:
      return `https://frontend.kws.check24.de/frontend/download/${fileName}`;
  }
};

interface WelcomeSectionProps {
  manualKwsRef: React.RefObject<HTMLDivElement>;
}

const ManualKWS: React.FC<WelcomeSectionProps> = ({ manualKwsRef }: WelcomeSectionProps) => {
  const { kwsState, refreshKwsState } = useKwsState();
  const [manualIban, setManualIban] = useState('');
  const [manualIbanValidationError, setManualIbanValidationError] = useState<ValidationResult>({
    valid: true,
  });
  const [isLoading, setIsLoading] = useState(false);

  const startManualKws = async () => {
    try {
      if (manualIban) {
        setIsLoading(true);
        await ServiceGateway.attachIbanSource(kwsState!.id, manualIban);
        refreshKwsState(undefined, () => {
          setIsLoading(false);
        });
      } else {
        setManualIbanValidationError({
          valid: false,
          message: 'Bitte geben Sie die IBAN Ihres bisherigen Kontos ein',
        });
      }
    } catch (err: any) {
      const errorData = err?.response?.data;
      if (errorData) {
        setManualIbanValidationError({ valid: false, message: errorData.firstErrorMessage });
      }
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <div className='manual-kws' data-testid='manual-kws' ref={manualKwsRef}>
        <div className='manual-kws-body'>
          <div className='header'>
            <div className='header-block'>
              <h2>Manueller Kontoumzug</h2>
              <Tooltip tooltipSectionName={TooltipStateEnum.MANUAL_KWS}>
                <p>
                  Ohne Anmeldung mit den Online-Banking Zugangsdaten Ihres bisherigen Kontos können
                  wir leider keine relevanten Zahlungspartner und Daueraufträge für Ihren Kontoumzug
                  ermitteln. Sie können Zahlungspartner aber manuell hinzufügen, die wir dann
                  automatisch über Ihre neue Bankverbindung informieren. Außerdem können Sie die
                  Schließung Ihres alten Kontos beauftragen und wir erledigen den Rest.
                </p>
              </Tooltip>
            </div>
          </div>
          <div className='description-bubble'>
            <p>Bitte geben Sie die IBAN Ihres bisherigen Kontos ein, das Sie umziehen möchten.</p>
          </div>
        </div>

        <IbanInput
          placeholder='IBAN eingeben'
          value={manualIban}
          onChange={setManualIban}
          validation={manualIbanValidationError}
        />

        <p className='manual-transfer-legal'>
          Mit Klick auf &quot;weiter ohne Online-Banking&quot; gelten die{' '}
          <a
            href={environmentLinkGenerator('TERMS_AND_CONDITIONS')}
            target='_blank'
            rel='noreferrer'
          >
            AGB
          </a>{' '}
          und{' '}
          <a href={environmentLinkGenerator('DATA_PROTECTION')} target='_blank' rel='noreferrer'>
            Datenschutzhinweise
          </a>{' '}
          der CHECK24 Vergleichsportal Karten & Konten GmbH, welche die von mir bereitgestellten
          Bankverbindungsdaten und Finanzinformationen verarbeitet und mir das Ergebnis
          bereitstellt.
        </p>

        <Button
          classNames='alternative blue-spinner'
          onClick={startManualKws}
          label='weiter ohne Online-Banking'
          loading={isLoading}
        />
      </div>
    </Container>
  );
};
export default ManualKWS;
