import React, { useState } from 'react';
import ServiceGateway from '../../../../../../services/ServiceGateway';
import { useKwsState } from '../../../../../../contexts/KwsStateContext/KwsStateContext';
import Checkbox from '../../../../../Common/Checkbox';
import { ReactComponent as AlertIcon } from '../../../../../../assets/alert.svg';
import { ReactComponent as CarretIcon } from '../../../../../../assets/carret.svg';
import { ReactComponent as TickIcon } from '../../../../../../assets/verified.svg';
import { ReactComponent as InfoIcon } from '../../../../../../assets/info.svg';
import {
  checkIsTransactionModified,
  formatAmount,
  removeNegativeSign,
  setLastScrollPosition,
} from '../../../../../../utils/helper';
import {
  HintSection,
  HintTypes,
} from '../../../../../../contexts/FrontendRuleContext/FrontendRule.types';
import { useOverview } from '../../../../../../contexts/OverviewContext/OverviewContext';
import Tooltip from '../../../../../Common/Tooltip';
import { ItemState } from '../../../../../../types/Overview/Common';
import { NotificationModel } from '../../../../../../types/Overview/NotificationModel';
import './index.scss';
import { TooltipStateEnum } from '../../../../../../contexts/TooltipStateContext/Tooltip.types';
import { useToastState } from '../../../../../../contexts/ToastStateContext/ToastStateContext';
import { useFrontendState } from '../../../../../../contexts/FrontendRuleContext/FrontendRuleContext';

interface NotificationProps {
  notification: NotificationModel;
}

const Notification = ({ notification }: NotificationProps) => {
  const { kwsState, setNotificationToProcess } = useKwsState();
  const { setToastList } = useToastState();
  const { updateNotification } = useOverview();
  const { decideHintToShow } = useFrontendState();
  const [disabledCheckbox, setDisabledCheckbox] = useState(false);

  function onCheck(id: string, v: boolean) {
    setDisabledCheckbox(true);
    const data = {
      customerChoice: [
        {
          id,
          selected: v,
        },
      ],
    };
    ServiceGateway.editNotifications(kwsState!.id, data).then((res) => {
      const { globalDate, notifications } = res.data;
      const [firstNotification] = notifications;

      if (firstNotification && globalDate) {
        updateNotification(firstNotification, globalDate);
        setDisabledCheckbox(false);

        if (firstNotification.state === ItemState.NOTIFY) {
          setToastList({
            id: crypto.randomUUID(),
            partnerName: firstNotification?.normalizedCounterName,
          });
          return;
        }

        if (firstNotification.state !== ItemState.TO_COMPLETE) {
          setToastList({
            id: crypto.randomUUID(),
            partnerName: firstNotification?.normalizedCounterName,
            isRemoved: true,
          });
        }
      }
    });
  }

  const openNotificationEditPage = () => {
    setLastScrollPosition();
    setNotificationToProcess({
      pageType: 'EDIT',
      id: notification.id,
    });
  };

  const checked =
    notification.state === ItemState.NOTIFY || notification.state === ItemState.TO_COMPLETE;
  const isHighlightedNotification =
    (notification.state === ItemState.INCOMPLETE && checked) ||
    notification.state === ItemState.TO_COMPLETE;
  const isCategoryCardPayments = notification.category === 'CARD_PAYMENTS';

  const isModified = checkIsTransactionModified(notification.id);
  const isIbanHintShown = decideHintToShow({
    type: HintTypes.NOTIFICATION,
    hint: HintSection.WE_PUT_NEW_IBAN_TO_SSO,
    itemId: notification.id,
  });
  const hasAmountOrHint =
    isHighlightedNotification || isModified || isIbanHintShown || notification.amount;

  return (
    <div
      className={`notification-item ${isHighlightedNotification ? 'highlight' : ''}`}
      data-testid='notification-item'
    >
      <div className='wrapper'>
        {isCategoryCardPayments ? null : (
          <div className={`notification-checkbox ${hasAmountOrHint ? '' : 'small-box'}`}>
            <Checkbox
              onChange={(e) => onCheck(notification.id, e)}
              checked={checked}
              inputId={notification.id}
              disabled={isHighlightedNotification || disabledCheckbox}
            />
          </div>
        )}
        <div
          className='details'
          onClick={openNotificationEditPage}
          data-testid='notification-details-section'
        >
          <div className='flex-wrapper'>
            <div className='information'>
              <div className={`name line-clamp-1 ${isHighlightedNotification ? 'highlight' : ''}`}>
                {notification.normalizedCounterName}
              </div>
              <div className='category'>{notification.categoryLocale}</div>
              {notification.amount && (
                <div className='payment-details'>
                  {isCategoryCardPayments ? (
                    <Tooltip tooltipSectionName={TooltipStateEnum.NOTIFICATION_PAYMENT_DETAILS}>
                      <p>
                        Hier finden Sie alle Zahlungen mit Ihrer Debitkarte. Für diese
                        Kartenzahlungen ist kein Umzug notwendig
                      </p>
                    </Tooltip>
                  ) : (
                    <>
                      <>
                        <div className='amount'>
                          {`${
                            notification.amount && Number(notification.amount) > 0 ? '+' : '-'
                          }${removeNegativeSign(formatAmount(notification.amount.toString()))} €`}
                        </div>
                        <div className='frequency'>{notification.frequencyLocale}</div>
                      </>

                      {notification.inactive && !checked && (
                        <div className='inactive-badge'>inaktiv</div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
            <div className='carret-color'>
              <CarretIcon className='rotate-90' />
            </div>
          </div>
          {isIbanHintShown && (
            <div className='warnings info' onClick={openNotificationEditPage}>
              <InfoIcon className='info-icon' />
              <div>
                Ihre neue IBAN wird zu Ihrem Kundenkonto hinzugefügt. Diese Änderung wirkt sich nur
                auf zukünftige Vergleiche, Anträge und Abschlüsse aus.
              </div>
            </div>
          )}
          {isHighlightedNotification ? (
            <div className='warnings alert' onClick={openNotificationEditPage}>
              <AlertIcon className='alert-icon' />
              <div>Daten ergänzen</div>
            </div>
          ) : (
            isModified && (
              <div className='warnings check' onClick={openNotificationEditPage}>
                <TickIcon className='modified-icon' />
                <div>Daten gespeichert</div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Notification;
