import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import ServiceGateway from '../../services/ServiceGateway';
import NotPossible from '../NotPossible';
import BirthdayCheck from './BirthdayCheck';
import Kws from '../Kws';
import TestPage from './TestPage';
import { useKwsState } from '../../contexts/KwsStateContext/KwsStateContext';
import MTanCheck from './MTanCheck';
import Login from './Login';
import SpinnerFullPage from '../Common/SpinnerFullPage';
import { isProd } from '../../utils/environment';

export enum EntryStateEnum {
  NEW = 'NEW',
  TEST = 'TEST',
  LOGIN = 'LOGIN',
  NOT_POSSIBLE = 'NOT_POSSIBLE',
  SSO_ATTACH_NEEDED = 'SSO_ATTACH_NEEDED',
  MTAN_CHECK = 'MTAN_CHECK',
  KWS = 'KWS',
  LOGOUT = 'LOGOUT',
}

interface EntryState {
  state: EntryStateEnum;
  hasSsoUserId: boolean;
  email: string;
  mobileNumber: string;
  loginType: string;
  loginPhone: string;
  message?: string;
}

const initialState: EntryState = {
  state: EntryStateEnum.NEW,
  hasSsoUserId: false,
  email: '',
  mobileNumber: '',
  loginType: 'check24',
  loginPhone: '',
};

export const Entry = () => {
  const { refreshKwsState } = useKwsState();
  const [entryState, setEntryState] = useState(() => initialState);

  const [searchParams] = useSearchParams();
  const kwsProcessId = searchParams.get('id');

  const startKws = () => {
    refreshKwsState(kwsProcessId!, () => {
      setEntryState({ ...entryState, state: EntryStateEnum.KWS });
    });
  };

  const updateState = async () => {
    const c24BankToken = searchParams.get('token');
    if (c24BankToken) {
      Cookies.set('c24bank-session', c24BankToken, {
        path: '/',
        sameSite: 'None',
        secure: true,
      });
    }

    try {
      const startResponse = await ServiceGateway.startProcess(kwsProcessId!);

      if (startResponse.status >= 200 && startResponse.status < 300) {
        const {
          email,
          mobileNumber,
          hasSsoUserId,
          loginType,
          loginPhone,
          processState,
          ismTanNeeded,
          userLoggedIn,
        } = { ...startResponse.data };

        if (!userLoggedIn) {
          setEntryState({
            ...entryState,
            state: EntryStateEnum.LOGIN,
            email,
            mobileNumber,
            hasSsoUserId,
            loginType,
            loginPhone,
          });
        } else if (processState === EntryStateEnum.SSO_ATTACH_NEEDED) {
          setEntryState({
            ...entryState,
            state: EntryStateEnum.SSO_ATTACH_NEEDED,
          });
        } else if (ismTanNeeded) {
          setEntryState({
            ...entryState,
            state: EntryStateEnum.MTAN_CHECK,
            mobileNumber,
          });
        } else {
          startKws();
        }
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        setEntryState({
          ...entryState,
          state: EntryStateEnum.NOT_POSSIBLE,
          message: error.response.data,
        });
      } else {
        setEntryState({
          ...entryState,
          state: EntryStateEnum.LOGOUT,
        });
      }
    }
  };

  useEffect(() => {
    if (!kwsProcessId) {
      if (!isProd()) {
        setEntryState({ ...entryState, state: EntryStateEnum.TEST });
      } else {
        window.location.href = `${window.location.origin}/start/error`;
      }
    } else {
      updateState();
    }
  }, []);

  const decideWhichPageToShow = () => {
    switch (entryState.state) {
      case EntryStateEnum.TEST:
        return <TestPage updateState={updateState} />;
      case EntryStateEnum.NOT_POSSIBLE:
        return <NotPossible message={entryState.message} />;
      case EntryStateEnum.NEW:
        return <SpinnerFullPage delay={100} />;
      case EntryStateEnum.LOGIN:
        return (
          <Login
            email={entryState.email}
            loginPhone={entryState.loginPhone}
            hasSsoUserId={entryState.hasSsoUserId}
            loginType={entryState.loginType}
          />
        );
      case EntryStateEnum.SSO_ATTACH_NEEDED:
        return <BirthdayCheck updateState={updateState} />;
      case EntryStateEnum.MTAN_CHECK:
        return <MTanCheck mobileNumber={entryState.mobileNumber} updateState={updateState} />;
      case EntryStateEnum.KWS:
        return <Kws />;
      default:
        return <SpinnerFullPage delay={100} />;
    }
  };

  return <div className='entry'>{decideWhichPageToShow()}</div>;
};
