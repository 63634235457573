import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import Spinner from '../../Common/Spinner';
import ServiceGateway from '../../../services/ServiceGateway';
import './index.scss';
import { useKwsState } from '../../../contexts/KwsStateContext/KwsStateContext';
import useDebounce from '../../../hooks/useDebounce';
import Container from '../../Common/Container';
import ContactCard from '../../Common/ContactCard';
import ProgressBar from '../../Common/ProgressBar';
import { ProcessStateEnum } from '../../../contexts/KwsStateContext/KwsState.types';
import InfoBox from '../../Common/InfoBox';
import Toggle from '../../Common/Toggle';
import Tooltip from '../../Common/Tooltip';
import { isProd } from '../../../utils/environment';
import { TooltipStateEnum } from '../../../contexts/TooltipStateContext/Tooltip.types';
import { DacPageModel } from '../../../types/DacPage/DacPageModel';
import WelcomeSection from './sections/WelcomeSection';
import FunctionSection from './sections/FunctionsSection';
import FinApiContainer from './sections/FinApiWebForm';
import ManualKWS from './sections/ManualKws';
import TinkContainer from './sections/TinkWidget';
import TextFieldAutoComplete from '../../Common/TextFieldAutoComplete';
import { DacProvider } from '../../../types/DacProvider';
import { DeviceOutputEnum } from '../../../utils/deviceOutput';
import { scrollToElement } from '../../../utils/helper';
import { PossibleBank } from '../../../services/ServiceGateway.types';

const Dac = () => {
  const { kwsState, refreshKwsState } = useKwsState();
  const [dacPageModel, setDacPageModel] = useState<DacPageModel>();

  const [processingTime, setProcessingTime] = useState<number>();
  const [processingCompleted, setProcessingCompleted] = useState(false);

  const [finApiToken, setFinApiToken] = useState<string>();
  const [tinkWizardKey, setTinkWizardKey] = useState<string>();

  const [providerError, setProviderError] = useState(false);
  const [providerLoading, setProviderLoading] = useState(false);

  const [showManualToggle, setShowManualToggle] = useState(false);
  const [expandManual, setExpandManual] = useState(false);
  const manualKwsRef = useRef<HTMLDivElement>(null);

  const [loadingBanks, setLoadingBanks] = useState(false);
  const [possibleBankList, setPossibleBankList] = useState<PossibleBank[]>([]);
  const [bankSearchText, setBankSearchText] = useState<string>('');
  const [bankData, setBankData] = useState<PossibleBank>();
  const [bankErrorMessage, setBankErrorMessage] = useState();
  const [bankSelectorDisplay, setBankSelectorDisplay] = useState('');
  const debouncedBankSearchText = useDebounce<string>(bankSearchText, 1000);

  const testBankList = [
    {
      code: '88888888',
      name: 'Tink Test Bank 1',
    },
    {
      code: '88888889',
      name: 'Tink Test Bank 2',
    },
    {
      code: 'DEMO0001',
      name: 'FINAPI Test Bank Embedded/Decoupled',
    },
    {
      code: 'DEMO0002',
      name: 'FINAPI Test Bank Redirect',
    },
  ];

  const initTinkProcess = async () => {
    setProviderLoading(true);

    try {
      const wizardKey = await ServiceGateway.initDac(kwsState!.id);
      setTinkWizardKey(wizardKey.data);
    } catch (error: any) {
      if (error?.response?.status === 400) {
        setProviderError(true);
      }
    } finally {
      setTimeout(() => {
        setProviderLoading(false);
      }, 1000);
    }
  };

  const initFinApiProcess = async () => {
    setProviderLoading(true);

    try {
      const finApiResponse = await ServiceGateway.initFinApi(
        kwsState!.id,
        window.wf_deviceoutput as DeviceOutputEnum,
      );
      setFinApiToken(finApiResponse.data.id);
    } catch (error: any) {
      if (error?.response?.status === 400) {
        setProviderError(true);
      }
    } finally {
      setTimeout(() => {
        setProviderLoading(false);
      }, 2000);
    }
  };

  const initProvider = async () => {
    if (providerError) {
      setProviderError(false);
    }

    const selectedProvider = await ServiceGateway.getProvider(kwsState!.id);
    switch (selectedProvider.data) {
      case DacProvider.TINK:
        await initTinkProcess();
        break;
      case DacProvider.FIN_API:
        await initFinApiProcess();
        break;
      default:
        Sentry.captureMessage(`unknown provider: ${selectedProvider.data}`);
        await initTinkProcess();
        break;
    }
  };

  const renderBankSelectorDisplay = (selectedBank: PossibleBank) => {
    // show without bank code if the branches grouped together
    if (selectedBank.name?.includes('Alle Filialen')) {
      return selectedBank.name.toString();
    }
    if (selectedBank.name && selectedBank.code) {
      return `${selectedBank.name} (BLZ ${selectedBank.code})`;
    }
    return selectedBank.code.toString();
  };

  const renderPossibleBankDisplayValue = (data: PossibleBank) => {
    const { name, code } = data;
    if (name?.includes('Alle Filialen')) {
      return <span>{name}</span>;
    }

    return <span>{`${name} (BLZ ${code})`}</span>;
  };

  const selectBank = async (bank?: PossibleBank) => {
    if (bank) {
      setBankData(bank);
      setBankSearchText('');
      setPossibleBankList([]);
      setBankErrorMessage(undefined);

      setTinkWizardKey(undefined);
      setFinApiToken(undefined);

      try {
        await ServiceGateway.attachBankCodeSource(kwsState!.id, bank.code);
        initProvider();
      } catch (bankSelectionError: any) {
        setProviderLoading(false);
        const errorData = bankSelectionError?.response?.data?.errors?.[0]?.message;
        if (errorData) {
          setBankErrorMessage(errorData);
        }
      }
      setBankSelectorDisplay(renderBankSelectorDisplay(bank));
    }
  };

  const onSelectBank = (bank?: PossibleBank) => {
    setTimeout(() => {
      const dacContainer = document.getElementsByClassName('bank-search')?.[0] as HTMLElement;
      scrollToElement(dacContainer);
    }, 500);

    selectBank(bank);
  };

  const getPossibleBankList = async (term: string) => {
    setLoadingBanks(true);
    setPossibleBankList([]);

    const { data: bankList } = await ServiceGateway.getPossibleBankList(term);

    if (!isProd()) {
      bankList.unshift(...testBankList);
    }

    setPossibleBankList(bankList);
    setLoadingBanks(false);
  };

  useEffect(() => {
    if (debouncedBankSearchText?.length > 1) {
      getPossibleBankList(debouncedBankSearchText);
      if (!showManualToggle) {
        setShowManualToggle(true);
      }
    } else {
      setPossibleBankList([]);
    }
  }, [debouncedBankSearchText]);

  const checkState = async () => {
    const fetchDacResult = await ServiceGateway.getProcessState(kwsState!.id);
    if (fetchDacResult.data.processState === ProcessStateEnum.EDITING) {
      setProcessingCompleted(true);

      setTimeout(() => {
        refreshKwsState();
      }, 500);
    } else {
      setTimeout(() => {
        checkState();
      }, 1000);
    }
  };

  const onProviderSuccess = (dacProcessingTime: number) => {
    setProcessingTime(dacProcessingTime);
    checkState();
  };

  const onRenewTinkProcess = () => {
    setTinkWizardKey(undefined);
    initTinkProcess();
  };

  const onRenewFinApiProcess = () => {
    setFinApiToken(undefined);
    initFinApiProcess();
  };

  const getDacPageData = async () => {
    const result = await ServiceGateway.getDacPage(kwsState!.id);
    setDacPageModel(result.data);

    const oldBankCode = result.data.oldBank?.bankCode;
    if (oldBankCode) {
      // fix bank code if there are spaces
      const cleanedBankCode = oldBankCode.replace(/\s+/g, '');

      const { data: bankList } = await ServiceGateway.getPossibleBankList(cleanedBankCode);
      const selectedBank = bankList && bankList.find((bank) => cleanedBankCode === bank.code);

      let bank: PossibleBank = {
        code: cleanedBankCode,
      };

      if (selectedBank?.name) {
        bank = { ...bank, name: selectedBank.name };
      }

      // Name fix for test banks
      if (!isProd() && !bank.name) {
        bank = testBankList.find((testBank) => cleanedBankCode === testBank.code)!;
      }

      selectBank(bank);
    }
  };

  useEffect(() => {
    if (expandManual) {
      const manualKwsElement = document.getElementsByClassName('manual-kws')?.[0] as HTMLElement;
      scrollToElement(manualKwsElement);
    }
  }, [expandManual]);

  useEffect(() => {
    getDacPageData();
  }, []);

  return (
    <div className='dac-page'>
      {dacPageModel && (
        <WelcomeSection customerData={dacPageModel.customerData} newBank={dacPageModel.newBank} />
      )}

      <FunctionSection />

      <Container>
        <div className='bank-search' data-testid='bank-search'>
          <div className='title'>Bei welcher Bank haben Sie Ihr bisheriges Girokonto?</div>
          <TextFieldAutoComplete
            searchText={bankSearchText}
            setSearchText={setBankSearchText}
            displayedValue={bankSelectorDisplay}
            errorMessage={bankErrorMessage}
            possibleInputList={possibleBankList}
            selectPossibleInput={onSelectBank}
            renderItemDisplayValue={renderPossibleBankDisplayValue}
            placeholder='Bankname oder BLZ'
            loading={loadingBanks}
          />
          {showManualToggle && (
            <Toggle
              text='bisherige Bank nicht verfügbar?'
              onClick={() => setExpandManual(!expandManual)}
              expandProp={expandManual}
            />
          )}
        </div>
      </Container>

      {bankData && !bankErrorMessage && (
        <Container>
          <div className='dac-container' data-testid='dac-container'>
            <div className='header'>
              <div className='header-block'>
                <h2>Verbindung mit Ihrem Bankkonto</h2>
                <Tooltip tooltipSectionName={TooltipStateEnum.DAC_HEADER}>
                  <p>
                    Die Anmeldung mit den Online-Banking Zugangsdaten Ihres bisherigen Kontos ist
                    TÜV-zertifiziert und notwendig, um anhand Ihrer Kontoumsätze relevante
                    Zahlungspartner und Daueraufträge für Ihren Kontoumzug zu ermitteln. Nachdem Sie
                    die Auswahl überprüft und bei Bedarf angepasst haben, kann Ihr Kontoumzug
                    automatisch ausgeführt werden. Außerdem können Sie die Schließung Ihres alten
                    Kontos beauftragen. Sie behalten über jeden Umzugsschritt die volle Kontrolle.
                  </p>
                  <br />
                  <p>
                    Ihre Daten werden ausschließlich in verschlüsselter Form an Ihre Bank
                    übermittelt. Ihre Log-In Daten werden nicht gespeichert. Sensible Daten wie
                    Benutzername oder Passwort sind für CHECK24 zu keinem Zeitpunkt einsehbar.
                  </p>
                </Tooltip>
              </div>
              {providerError && (
                <InfoBox
                  title='Bank Login derzeit nicht möglich'
                  button={{ label: 'neu laden', onClick: initProvider }}
                  type='alert'
                >
                  <p>
                    Aufgrund von Wartungsarbeiten kann der Zugang zu Ihrem Bankkonto derzeit nicht
                    hergestellt werden. Bitte versuchen Sie es später erneut, um den Kontoumzug
                    fortzusetzen. Für Rückfragen stehen wir Ihnen gerne unter 089 - 24 24 11 09 zur
                    Verfügung.
                  </p>
                </InfoBox>
              )}
            </div>
            {providerLoading && (
              <div className='spinner-container'>
                <Spinner />
              </div>
            )}
            <div className={providerLoading ? 'hidden-while-loading' : ''}>
              {tinkWizardKey && !finApiToken && (
                <TinkContainer
                  tinkToken={tinkWizardKey}
                  onSuccess={onProviderSuccess}
                  renewTinkProcess={onRenewTinkProcess}
                />
              )}
              {finApiToken && !tinkWizardKey && (
                <FinApiContainer
                  finApiToken={finApiToken}
                  onSuccess={onProviderSuccess}
                  renewFinApiProcess={onRenewFinApiProcess}
                />
              )}
            </div>

            <Toggle
              text='Kontoumzug ohne Online-Banking'
              onClick={() => setExpandManual(!expandManual)}
              expandProp={expandManual}
            />
          </div>
        </Container>
      )}

      {expandManual && <ManualKWS manualKwsRef={manualKwsRef} />}

      {processingTime && (
        <ProgressBar expectedWaitingTimeInSeconds={processingTime} done={processingCompleted} />
      )}

      <ContactCard />
    </div>
  );
};

export default Dac;
