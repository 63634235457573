import React, { useState } from 'react';
import './index.scss';
import ServiceGateway from '../../../../../services/ServiceGateway';
import { useKwsState } from '../../../../../contexts/KwsStateContext/KwsStateContext';
import ResetButton from '../../../../Common/ResetButton';
import { ConfirmationOrErrorModel } from '../../../../../types/ConfirmationErrorModel';
import ConfirmationModal from '../../../CommonSections/ConfirmationModal';

const confirmationData: ConfirmationOrErrorModel = {
  title: 'Kontoumzug neu starten?',
  message:
    'Über den Button “neu starten” gelangen Sie zurück zum Login und können den automatischen Kontoumzug mit Online-Banking wählen.',
  hint: 'Ihre bisherigen Eingaben werden gelöscht.',
};

const ResetSection = () => {
  const { kwsState, refreshKwsState } = useKwsState();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const submit = () => {
    if (!loading) {
      setLoading(true);
      ServiceGateway.resetProcess(kwsState!.id).then(() => {
        refreshKwsState(undefined, () => setLoading(false));
      });
    }
  };

  return (
    <div className='reset-section'>
      <ResetButton onClick={() => setShowModal(true)} title='zurück zum Login' />

      {showModal && (
        <ConfirmationModal
          onCancel={() => setShowModal(false)}
          onSubmit={submit}
          submitLabel='neu starten'
          isLoading={loading}
          hasCancelButton
        >
          <div className='confirmation-modal-content'>
            <p>{confirmationData.title}</p>
            <p>{confirmationData.message}</p>
            <p>
              <b>Hinweis:</b> {confirmationData.hint}
            </p>
          </div>
        </ConfirmationModal>
      )}
    </div>
  );
};

export default ResetSection;
