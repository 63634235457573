import React, { useState } from 'react';
import { ReactComponent as CarretIcon } from '../../../../../../../assets/carret.svg';
import TimelineStepDetail from '../TimelineStepDetail';
import './index.scss';
import { TimelineStatusEnum, TimelineStepModel } from '../../../../../../../types/Timeline';
import { generatePdfLink } from '../../../../../../../utils/linkGenerator';

interface TimelineStepProps {
  step: TimelineStepModel;
}

const TimelineStep = ({ step }: TimelineStepProps) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div
      className={`timeline-step ${step.status === TimelineStatusEnum.COMPLETED ? 'active ' : ''}`}
      data-testid='timeline-step'
    >
      <div className='title'>{step.name}</div>
      <div className='description'>{step.description}</div>
      {step.link && (
        <div className='download-link'>
          <a
            href={generatePdfLink(step.link.url, 'downloadPdfSummary')}
            target='_blank'
            rel='noreferrer'
          >
            <span className='link'>{step.link.description}</span>
          </a>
        </div>
      )}
      {step.details && (
        <div className='details'>
          <span
            className='detail-button'
            data-testid='timeline-step-detail-button'
            onClick={() => setShowDetails(!showDetails)}
          >
            Details
            <CarretIcon className={`carret${showDetails ? ' open' : ''}`} />
          </span>
          {showDetails && (
            <div className='timeline-steps-details'>
              {step.details.map((s, i) => (
                <TimelineStepDetail key={i} data={s} type={step.type} />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TimelineStep;
