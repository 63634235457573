import React, { useEffect } from 'react';
import './index.scss';
import { ReactComponent as CheckIcon } from '../../../assets/check-circle-outlined.svg';
import { ReactComponent as CrossIcon } from '../../../assets/cross_outlined.svg';
import { useToastState } from '../../../contexts/ToastStateContext/ToastStateContext';

interface ToastBoxProps {
  type?: 'notification' | 'standingOrder';
  partnerName: string;
  id: string;
  toastTime?: number;
  isRemoved: boolean | undefined;
}

const ToastBox = ({
  type = 'notification',
  partnerName,
  id,
  isRemoved,
  toastTime = 3000,
}: ToastBoxProps) => {
  const { removeToast } = useToastState();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(id);
    }, toastTime);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`toast-box ${isRemoved ? 'removed' : ''}`} data-testid='toast-box'>
      <div className='header'>
        {isRemoved ? (
          <>
            <CrossIcon className='icon' />
            <p>
              {type !== 'standingOrder'
                ? `${partnerName} wird nicht informiert`
                : `Dauerauftrag an ${partnerName} wird nicht eingerichtet`}
            </p>
          </>
        ) : (
          <>
            <CheckIcon className='icon' />
            <p>
              {type !== 'standingOrder'
                ? `${partnerName} wird informiert`
                : `Dauerauftrag an ${partnerName} wird eingerichtet`}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default ToastBox;
