import React, { useEffect, useState } from 'react';
import './index.scss';
import { formatIbanLook } from '../../../hooks/useIbanFormat';

interface BankCardProps {
  isTop?: boolean;
  name: string;
  iban?: string;
  logoName?: string;
}

const BankCard = ({ isTop = false, name, iban, logoName }: BankCardProps) => {
  const [logoSrc, setLogoSrc] = useState<string>('');

  const importLogo = async () => {
    try {
      const logo = await import(`../../../assets/banks/${logoName}.svg`);
      setLogoSrc(logo.default);
    } catch (error) {
      const fallbackLogo = await import('../../../assets/banks/bank-default.svg');
      setLogoSrc(fallbackLogo.default);
    }
  };

  const getIbanText = () => {
    if (iban) {
      return formatIbanLook(iban);
    }
    return 'IBAN wird nachgereicht';
  };

  const isDefault = logoSrc.includes('bank-default');

  useEffect(() => {
    importLogo();
  }, [logoName]);

  return (
    <div className={`bank ${isTop ? 'top' : ''}`} data-testid='bank-card-page'>
      <div className={`logo ${isDefault ? '' : 'without-circle'}`}>
        <img
          className={`logo-image  ${isDefault ? 'with-circle' : ''}`}
          src={logoSrc}
          alt=''
          data-testid='image-test'
        />
      </div>
      <div className={`bank-data ${isTop ? 'top' : ''}`}>
        <p className={`bank-name line-clamp-1 ${isTop ? 'top' : ''}`} data-testid='name-test'>
          {name}
        </p>
        <p className='bank-iban' data-testid='iban-test'>
          {getIbanText()}
        </p>
      </div>
    </div>
  );
};

export default BankCard;
