import React, { useEffect, useRef, useState } from 'react';
import isEqual from 'lodash.isequal';
import Validators, { ValidationResult } from '../../../utils/validators';
import './index.scss';
import TextInput from '../TextInput';
import PostFachInput from './Postfach/index';

interface AddressInputProps {
  addressProps?: AddressState;
  onAddressChange?: (addressState: AddressState | undefined) => void;
  validations?: Record<string, ValidationResult>;
  onError?: (validation: ValidationResult, fieldName: string) => void;
  showHintField?: 'INITIALLY' | 'AFTER_FOCUS' | 'NOT_SHOW';
  isPrivateNotification?: boolean;
  isDisabled?: boolean;
}

interface AddressState {
  street?: string;
  city?: string;
  streetNum?: string;
  postalCode?: string;
  postfach?: string;
}

const initialState: AddressState = {
  street: '',
  city: '',
  streetNum: '',
  postalCode: '',
  postfach: '',
};

const AddressInput = ({
  addressProps,
  onAddressChange,
  validations,
  onError,
  showHintField = 'NOT_SHOW',
  isPrivateNotification = false,
  isDisabled,
}: AddressInputProps) => {
  const [addressState, setAddressState] = useState<AddressState | undefined>(initialState);
  const [streetFocus, setStreetFocus] = useState(false);
  const [streetNumFocus, setStreetnumFocus] = useState(false);
  const [postalCodeFocus, setPostalCodeFocus] = useState(false);
  const [cityFocus, setCityFocus] = useState(false);
  const addressRef = useRef<AddressState>();
  const firstPropsUpdateRef = useRef(false);
  const firstTimeShowHintsForAddressFields = useRef(showHintField === 'INITIALLY');

  useEffect(() => {
    if (firstPropsUpdateRef.current) {
      if (onAddressChange) {
        onAddressChange(addressState);
      }
    }
  }, [addressState]);

  useEffect(() => {
    if (!isEqual(addressRef.current, addressProps)) {
      firstPropsUpdateRef.current = true;
      addressRef.current = addressProps;
      setAddressState(addressProps);
    }
  }, [addressProps]);

  const decideToShowHint = () =>
    showHintField !== 'NOT_SHOW' &&
    firstTimeShowHintsForAddressFields.current && (
      <div className='hint-field'>
        Bitte überprüfen Sie die Anschrift auf Vollständigkeit und Richtigkeit.
      </div>
    );

  return (
    <div className='address-input'>
      <div className='street-streetNo-field'>
        <div className='input-field'>
          <TextInput
            className='w-66p'
            onChange={(v) =>
              setAddressState({
                ...addressState,
                street: v,
              })
            }
            value={addressState?.street || ''}
            placeholder='Straße'
            validator={
              isPrivateNotification ? Validators.moreThan2() : Validators.moreThan2orNull()
            }
            // validator={Validators.moreThan2orNull()}
            validation={validations?.street}
            onError={(e) => {
              if (onError) {
                onError(e, 'street');
              }
            }}
            // optional
            disableErrorText
            onFocusChange={(f) => {
              setStreetFocus(f);
              firstTimeShowHintsForAddressFields.current = true;
            }}
            disabled={isDisabled}
          />
          <TextInput
            className='w-33p'
            value={addressState?.streetNum || ''}
            onChange={(v) =>
              setAddressState({
                ...addressState,
                streetNum: v,
              })
            }
            placeholder='Nr.'
            onError={(e) => {
              if (onError) {
                onError(e, 'streetNum');
              }
            }}
            validation={validations?.streetNum}
            disableErrorText
            validator={isPrivateNotification ? Validators.empty() : undefined}
            // optional
            // optionalText='(opt.)'
            onFocusChange={(f) => {
              setStreetnumFocus(f);
              firstTimeShowHintsForAddressFields.current = true;
            }}
            disabled={isDisabled}
          />
        </div>
        {decideToShowHint()}
        {!(streetFocus || streetNumFocus) &&
          (validations?.street?.valid === false || validations?.streetNum?.valid === false) && (
            <div className='error-field'>
              {validations?.street?.message || validations?.streetNum?.message}
            </div>
          )}
      </div>

      <PostFachInput
        className='w-full'
        value={addressState?.postfach || ''}
        onChange={(v) =>
          setAddressState({
            ...addressState,
            postfach: v,
          })
        }
        placeholder='Postfach'
        onError={(e) => {
          if (onError) {
            onError(e, 'postfach');
          }
        }}
        validation={validations?.postfach}
        optional
        inputMode='numeric'
        inputRegex={/[^0-9]/g}
        onFocusChange={(f) => {
          setStreetFocus(f);
          firstTimeShowHintsForAddressFields.current = true;
        }}
        disabled={isDisabled}
      />

      <div className='postalCode-city-field'>
        <div className='input-field'>
          <TextInput
            className='w-50p'
            value={addressState?.postalCode || ''}
            onChange={(v) =>
              setAddressState({
                ...addressState,
                postalCode: v,
              })
            }
            placeholder='PLZ'
            inputMode='numeric'
            validator={Validators.zipCode()}
            onError={(e) => {
              if (onError) {
                onError(e, 'postalCode');
              }
            }}
            validation={validations?.postalCode}
            disableErrorText
            onFocusChange={setPostalCodeFocus}
            disabled={isDisabled}
          />
          <TextInput
            className='w-50p'
            value={addressState?.city || ''}
            onChange={(v) =>
              setAddressState({
                ...addressState,
                city: v,
              })
            }
            placeholder='Ort'
            validator={Validators.moreThan2()}
            onError={(e) => {
              if (onError) {
                onError(e, 'city');
              }
            }}
            validation={validations?.city}
            disableErrorText
            onFocusChange={setCityFocus}
            disabled={isDisabled}
          />
        </div>

        {!(postalCodeFocus || cityFocus) &&
          (validations?.fullAddress?.valid === false ||
            validations?.postalCode?.valid === false ||
            validations?.city?.valid === false) && (
            <div className='error-field'>
              {validations?.fullAddress?.message ||
                validations?.postalCode?.message ||
                validations?.city?.message}
            </div>
          )}
      </div>
    </div>
  );
};

export default AddressInput;
