import React from 'react';
import './index.scss';
import { ReactComponent as CarretIcon } from '../../../assets/carret.svg';
import { ReactComponent as AlertIcon } from '../../../assets/alert.svg';

interface AlertCardProps {
  children?: React.ReactNode;
  title?: string;
  show?: boolean;
  onChange: () => void;
}

const AlertCardV2 = ({ children, title, show, onChange }: AlertCardProps) => (
  <div className='alert-card-v2'>
    <div className='alert-wrapper'>
      <div className='alert-header' onClick={onChange}>
        <div className='alert-title-wrapper'>
          <AlertIcon className='alert-icon' />
          <p className={`alert-title ${show && 'bold'}`}>{title}</p>
        </div>
        <CarretIcon className={`carret ${show ? 'open' : ''}`} />
      </div>
      {show && <div className='alert-body'>{children}</div>}
    </div>
  </div>
);

export default AlertCardV2;
