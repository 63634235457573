import React from 'react';
import './index.scss';

import Container from '../../../../Common/Container';
import { ReactComponent as CheckIcon } from '../../../../../assets/check2.svg';
import { formatDate, isApprovedState } from '../../../../../utils/helper';
import Tooltip from '../../../../Common/Tooltip';
import { useOverview } from '../../../../../contexts/OverviewContext/OverviewContext';
import { TooltipStateEnum } from '../../../../../contexts/TooltipStateContext/Tooltip.types';
import { NotificationModel } from '../../../../../types/Overview/NotificationModel';
import { StandingOrderModel } from '../../../../../types/Overview/StandingOrderModel';
import { useKwsState } from '../../../../../contexts/KwsStateContext/KwsStateContext';
import InfoBox from '../../../../Common/InfoBox';
import { ProcessStateEnum } from '../../../../../contexts/KwsStateContext/KwsState.types';

const SuccessSection = () => {
  const { overviewData } = useOverview();
  const { globalDate, notifications, standingOrders, closingData, customerData } = overviewData!;
  const { kwsState } = useKwsState();

  const anyApprovedState = (data: NotificationModel[] | StandingOrderModel[]): boolean =>
    data.some((item: NotificationModel | StandingOrderModel) => isApprovedState(item.state));

  const isAccountClosingOnly =
    isApprovedState(closingData.state) &&
    !(anyApprovedState(notifications) || anyApprovedState(standingOrders));

  return (
    <Container>
      <div className='success-section'>
        <div className='success'>
          <div className='flex'>
            <CheckIcon className='check' />
            <h2 data-testid='success-msg' className='text'>
              {isAccountClosingOnly
                ? 'Vielen Dank, Ihre Kontoschließung wurde beauftragt'
                : 'Vielen Dank, Ihr Kontoumzug wurde beauftragt'}
            </h2>
          </div>
          <Tooltip tooltipSectionName={TooltipStateEnum.SUCCESS_SECTION}>
            <p>
              Im Folgenden haben wir für Sie nochmals alle durchgeführten Umzugsschritte
              zusammengefasst. Einige davon benötigen ggf. noch Ihre Mithilfe.
            </p>
            <br />
            <p>
              Bei Bedarf können wir jederzeit bisher noch nicht ausgewählte Umzugsschritte für Sie
              durchführen. Auf diese Weise wird ein Kontoumzug in mehreren Etappen möglich.
            </p>
          </Tooltip>
        </div>
        <p className='title'>
          Wir haben Ihnen eine Bestätigung an <span>{customerData.email}</span> gesendet.
        </p>
        <div className='account-info'>
          <p className='row'>
            <span>Vorgangsnummer</span>
            <span className='data'>{kwsState!.processNumber}</span>
          </p>
          <p className='row'>
            <span>{isAccountClosingOnly ? 'Kontoschließung' : 'Kontoumzugsdatum'}</span>
            <span className='data' data-testid='date-info'>
              {globalDate.allSwitchingDatesMatch ? '' : 'ab '}
              {isAccountClosingOnly
                ? formatDate(globalDate.accountClosingDate)
                : formatDate(globalDate.globalSwitchingDate)}
            </span>
          </p>
        </div>

        {kwsState?.state === ProcessStateEnum.WAITING_FOR_IBAN_FROM_BANK && (
          <div className='success-info-box-wrapper' data-testid='success-waiting-for-iban'>
            <InfoBox type='info'>
              <p>
                Die {closingData.newBank.name} übermittelt Ihre neue IBAN automatisch innerhalb von
                wenigen Tagen, sodass Ihr Kontoumzug durchgeführt werden kann.
              </p>
            </InfoBox>
          </div>
        )}
      </div>
    </Container>
  );
};

export default SuccessSection;
