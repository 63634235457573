import React from 'react';
import { useSearchParams } from 'react-router-dom';
import ContactCard from '../Common/ContactCard';
import KontoumzugSection from './sections/KontoumzugSection';
import AdvantageSection from './sections/AdvantageSection';
import FaqSection from './sections/FaqSection';
import EntrySection from './sections/EntrySection';
import './index.scss';
import { DeviceOutputEnum } from '../../utils/deviceOutput';

const outCome = ['default', 'cta', 'info', 'productinfo', 'teaser'] as const;
type OutCome = (typeof outCome)[number];
const isOutCome = (param: unknown): param is OutCome =>
  typeof param === 'string' && outCome.includes(param as OutCome);

const LandingPage = () => {
  const deviceOutputApp = window.wf_deviceoutput === DeviceOutputEnum.APP;
  const [searchParams] = useSearchParams();
  const outComeParam = searchParams.get('outcome');
  const outComeSelected = isOutCome(outComeParam) ? outComeParam : 'default';

  return (
    <div
      className={`landing-page${
        outComeSelected === 'productinfo' && deviceOutputApp ? ' product-info-app' : ''
      }`}
      data-testid='landing-page'
    >
      <KontoumzugSection outCome={outComeSelected} />
      <EntrySection outCome={outComeSelected} />
      <AdvantageSection />
      <FaqSection />
      <ContactCard />
    </div>
  );
};

export { OutCome };
export default LandingPage;
