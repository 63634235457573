import React, { useState } from 'react';
import './index.scss';
import { ReactComponent as SortIcon } from '../../../assets/sort.svg';
import { ReactComponent as CancelIcon } from '../../../assets/cancel.svg';
import Button from '../Button';
import RadioButtonGroup, { RadioButtonGroupLayoutDirection, RadioProps } from '../RadioButtonGroup';

interface SortProps {
  selected: any;
  relevancy: string;
  onChange: (value: any) => void;
}

const Sort = ({ selected, relevancy, onChange }: SortProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const [sortValue, setSortValue] = useState(selected || 'amount');
  const [selectedV, setSelected] = useState(selected || 'amount');

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const onSort = (value: any) => {
    setSortValue(value);
  };
  const sort = () => {
    onChange(sortValue);
    setSelected(sortValue);
    setShowMenu(!showMenu);
  };
  const translate = (value: any) => {
    switch (value) {
      case 'amount':
        return 'Betrag';
      case 'frequency':
        return 'Frequenz';
      case 'category':
        return 'Kategorie';
      case 'alphabetical':
        return 'Alphabet';
      default:
        return '';
    }
  };

  const relevantButtons =
    relevancy !== 'irrelevant'
      ? [
          {
            onChange: onSort,
            name: relevancy,
            label: 'Kategorie',
            value: 'category',
            checked: sortValue === 'category',
          },
          {
            onChange: onSort,
            name: relevancy,
            label: 'Alphabet',
            value: 'alphabetical',
            checked: sortValue === 'alphabetical',
          },
        ]
      : [];

  const radioButtons: RadioProps[] = [
    {
      onChange: onSort,
      name: relevancy,
      label: 'Betrag',
      value: 'amount',
      checked: sortValue === 'amount',
    },
    {
      onChange: onSort,
      name: relevancy,
      label: 'Frequenz',
      value: 'frequency',
      checked: sortValue === 'frequency',
    },
    ...relevantButtons,
  ];

  return (
    <div>
      <div onClick={toggleMenu} className='sort'>
        <SortIcon />
        <div>
          {selectedV && selectedV !== 'amount' && <div className='indicator' />}
          <div>Sortieren</div>
          <div className='subtext'>{translate(selectedV)}</div>
        </div>
      </div>
      <div className={`sort-modal ${showMenu ? '' : 'hide'}`}>
        <div className='sort-modal__menu'>
          <CancelIcon
            onClick={() => {
              setShowMenu(false);
            }}
            className='close'
          />
          <div className='sort-modal-menu-content'>
            <RadioButtonGroup
              buttons={radioButtons}
              groupLabel='Sortieren nach'
              layoutDirection={RadioButtonGroupLayoutDirection.VERTICAL}
              isWithoutBorder
            />

            <Button onClick={sort} label='Sortierung übernehmen' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sort;
