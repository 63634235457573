import React, { useEffect } from 'react';
import { ItemType, TransactionModel } from '../../../../types/Overview/Common';
import InnerBorderContainer from '../../../Common/InnerBorderContainer';
import { formatAmount, formatDate, removeNegativeSign } from '../../../../utils/helper';
import './index.scss';

type AllTransactionsTabProps = {
  transactions: TransactionModel[];
  pageType?: ItemType;
};

const AllTransactionsTab = ({ transactions, pageType }: AllTransactionsTabProps) => {
  useEffect(() => {
    transactions?.sort(
      (a: TransactionModel, b: TransactionModel) =>
        new Date(b.bookingDate || Date.now()).valueOf() -
        new Date(a.bookingDate || Date.now()).valueOf(),
    );
  }, [transactions]);

  return (
    <div className='all-transactions-tab' data-testid='all-transactions-tab'>
      <p className='title'>
        {pageType === ItemType.NOTIFICATION
          ? 'Für diesen Zahlungspartner wurden folgende Transaktionen erkannt.'
          : 'Für diesen Dauerauftrag wurden folgende Transaktionen erkannt.'}
      </p>

      <InnerBorderContainer classNames='bg-white'>
        {transactions.map((transaction, index) => (
          <div
            className='transaction-item'
            key={`${transaction.counterName}-${index}`}
            data-testid='transaction-item'
          >
            <div className='header'>
              <div className='left'>
                <div className='name line-clamp-1'>{transaction.counterName}</div>
                <div className='date'>{formatDate(transaction.bookingDate)}</div>
              </div>
              <div className='right'>
                <div className='amount'>
                  {`${transaction.amount > 0 ? '+' : '-'}${removeNegativeSign(
                    formatAmount(transaction.amount.toString()),
                  )} €`}
                </div>
              </div>
            </div>

            <p className='description'>{transaction.subject}</p>
          </div>
        ))}
      </InnerBorderContainer>
    </div>
  );
};

export default AllTransactionsTab;
