import React, { useEffect } from 'react';
import Dac from './Dac';
import Overview from './Overview';
import Thanks from './Thanks';
import DigitalSignature from './DigitalSignature';
import NotPossible from '../NotPossible';
import { useKwsState } from '../../contexts/KwsStateContext/KwsStateContext';
import NotificationDetails from './DetailPages/NotificationDetails';
import AccountClosingDetails from './DetailPages/AccountClosingDetails';
import StandingOrderDetails from './DetailPages/StandingOrderDetails';
import SwitchingDateDetails from './DetailPages/SwitchingDateDetails';
import InvalidSwitchingDates from './InvalidSwitchingDates';
import SpinnerFullPage from '../Common/SpinnerFullPage';
import { ProcessStateEnum } from '../../contexts/KwsStateContext/KwsState.types';
import Restricted from './Restricted';
import OverviewProvider from '../../contexts/OverviewContext/OverviewProvider';
import './index.scss';
import { useTooltipState } from '../../contexts/TooltipStateContext/TooltipStateContext';
import ToastStateProvider from '../../contexts/ToastStateContext/ToastStateProvider';
import FrontendRuleProvider from '../../contexts/FrontendRuleContext/FrontendRuleProvider';

let interval: any;
const Kws = () => {
  const {
    kwsState,
    notificationToProcess,
    switchingDateToProcess,
    standingOrderToProcess,
    accountToProcess,
    setSwitchingDateToProcess,
    setAccountToProcess,
    setStandingOrderToProcess,
    setNotificationToProcess,
    refreshKwsState,
  } = useKwsState();
  const { tooltipState } = useTooltipState();

  useEffect(() => {
    if (kwsState!.state !== ProcessStateEnum.DAC_NEEDED) {
      window.history.pushState(null, '', window.location.href);
      window.onpopstate = () => {
        setNotificationToProcess(undefined);
        setStandingOrderToProcess(undefined);
        setAccountToProcess(false);
        setSwitchingDateToProcess(false);
        window.history.pushState(null, '', window.location.href);
      };
    }
    if (kwsState!.state === ProcessStateEnum.DAC_ANALYSIS_NEEDED) {
      interval = setInterval(() => {
        refreshKwsState();
      }, 3000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [kwsState!.state]);

  const decideWhichPageToShow = () => {
    const noEditItemSelected =
      !notificationToProcess &&
      !accountToProcess &&
      !switchingDateToProcess &&
      !standingOrderToProcess;

    const statesWithDetailPages =
      kwsState!.state === ProcessStateEnum.EDITING ||
      kwsState!.state === ProcessStateEnum.READY ||
      kwsState!.state === ProcessStateEnum.WAITING_FOR_IBAN_FROM_BANK ||
      kwsState!.state === ProcessStateEnum.WAITING_FOR_IBAN_FROM_CUSTOMER;

    // order below this line is important. be careful!!!
    if (kwsState!.state === ProcessStateEnum.CANCELED) {
      return <Restricted message='Ihr CHECK24 Kontoumzug wurde storniert' />;
    }
    if (kwsState!.archived) {
      if (kwsState!.state === ProcessStateEnum.READY) {
        return <Restricted success message='Ihr CHECK24 Kontoumzug wurde durchgeführt!' />;
      }
      return <Restricted message='Leider ist Ihr CHECK24 Kontoumzug abgelaufen' />;
    }
    if (kwsState!.invalidSwitchingDates) {
      return <InvalidSwitchingDates />;
    }
    if (kwsState!.state === ProcessStateEnum.NOT_POSSIBLE) {
      return <NotPossible />;
    }
    if (kwsState!.state === ProcessStateEnum.DAC_NEEDED) {
      return <Dac />;
    }
    if (kwsState!.state === ProcessStateEnum.DAC_ANALYSIS_NEEDED) {
      return (
        <SpinnerFullPage text='Bitte haben Sie etwas Geduld, wir analysieren Ihre Kontoumsätze. Dies kann mehrere Minuten dauern.' />
      );
    }
    if (kwsState!.state === ProcessStateEnum.EDITING && noEditItemSelected) {
      return (
        <FrontendRuleProvider>
          <OverviewProvider>
            <ToastStateProvider>
              <Overview />
            </ToastStateProvider>
          </OverviewProvider>
        </FrontendRuleProvider>
      );
    }
    if (
      (kwsState!.state === ProcessStateEnum.READY ||
        kwsState!.state === ProcessStateEnum.WAITING_FOR_IBAN_FROM_CUSTOMER ||
        kwsState!.state === ProcessStateEnum.WAITING_FOR_IBAN_FROM_BANK) &&
      noEditItemSelected
    ) {
      return (
        <FrontendRuleProvider>
          <OverviewProvider>
            <ToastStateProvider>
              <Thanks />
            </ToastStateProvider>
          </OverviewProvider>
        </FrontendRuleProvider>
      );
    }
    if (statesWithDetailPages && notificationToProcess) {
      return <NotificationDetails />;
    }
    if (statesWithDetailPages && standingOrderToProcess) {
      return <StandingOrderDetails />;
    }
    if (statesWithDetailPages && accountToProcess) {
      return (
        <FrontendRuleProvider>
          <OverviewProvider>
            <AccountClosingDetails />
          </OverviewProvider>
        </FrontendRuleProvider>
      );
    }
    if (statesWithDetailPages && switchingDateToProcess) {
      return (
        <FrontendRuleProvider>
          <OverviewProvider>
            <SwitchingDateDetails />
          </OverviewProvider>
        </FrontendRuleProvider>
      );
    }
    if (kwsState!.state === ProcessStateEnum.SIGNING) {
      return (
        <FrontendRuleProvider>
          <DigitalSignature />;
        </FrontendRuleProvider>
      );
    }

    return <NotPossible />;
  };

  return (
    <div className={`kws ${tooltipState ? 'desktop-side-view' : ''}`}>
      {decideWhichPageToShow()}
    </div>
  );
};

export default Kws;
