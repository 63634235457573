import React, { useEffect, useState } from 'react';
import './index.scss';

interface SpinnerProps {
  delay?: number;
}

const Spinner = ({ delay }: SpinnerProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        setShow(true);
      }, delay);
    } else {
      setShow(true);
    }
  }, []);

  return show ? <div className='spinner-loader' data-testid='spinner-loader' /> : null;
};

export default Spinner;
