import React from 'react';
import './index.scss';

import checkIcon from '../../../../assets/landing/check-icon.png';
import guarantee from '../../../../assets/landing/guarantee.png';
import protection from '../../../../assets/landing/protection.png';
import tuv from '../../../../assets/landing/tuv.png';
import { guaranteeLinkGenerator } from '../../utils/linkGenerator';

const AdvantageSection = () => (
  <div className='advantage-section' data-testid='advantage-section'>
    <div className='wrapper'>
      <h2>Ihre Vorteile</h2>
      <div className='advantages'>
        <div className='advantage'>
          <div className='title'>
            <img src={checkIcon} alt='' />
            <p>100% Sorglos-Garantie</p>
          </div>
          <p>
            Wir garantieren Ihnen bei Nutzung des CHECK24 Kontoumzugs einen reibungslosen Ablauf.
            Sollten Ihnen dabei dennoch unerwartete Kosten entstehen, so übernimmt CHECK24 diese
            Kosten für Sie. {guaranteeLinkGenerator()}
          </p>
        </div>

        <div className='advantage'>
          <div className='title'>
            <img src={checkIcon} alt='' />
            <p>Datenschutzgarantie</p>
          </div>
          <p>
            Die Anmeldung in Ihrem alten Konto erfolgt verschlüsselt und wurde vom TÜV-Süd
            zertifiziert. Ihre Log-In Daten werden von CHECK24 nicht gespeichert.
          </p>
        </div>

        <div className='advantage'>
          <div className='title'>
            <img src={checkIcon} alt='' />
            <p>Einfach und volle Kontrolle</p>
          </div>
          <p>
            Wir analysieren für Sie, was wirklich auf das neue Konto umgezogen werden muss. Sie
            bestätigen den Kontoumzug mit einem Klick oder bestimmen alle Details selbst. Dabei
            behalten Sie die volle Kontrolle. Wir erledigen den Rest.
          </p>
        </div>

        <div className='advantage'>
          <div className='title'>
            <img src={checkIcon} alt='' />
            <p>Zeitplan nach Ihren Wünschen</p>
          </div>
          <p>
            Bestimmen Sie den gewünschten Umzugstermin oder führen Sie den Kontoumzug in
            verschiedenen Etappen durch. Wir stellen sicher, dass alles zeitlich zusammenpasst und
            die nötigen Zeitpuffer eingehalten werden.
          </p>
        </div>
      </div>
      <div className='logos'>
        <img src={guarantee} alt='' />
        <img src={protection} alt='' />
        <img src={tuv} alt='' />
      </div>
    </div>
  </div>
);

export default AdvantageSection;
