import React, { useState } from 'react';
import './index.scss';
import Container from '../../../../Common/Container';

import { ReactComponent as DacIcon1 } from '../../../../../assets/dac-page-1.svg';
import { ReactComponent as DacIcon2 } from '../../../../../assets/dac-page-2.svg';
import { ReactComponent as DacIcon3 } from '../../../../../assets/dac-page-3.svg';
import { ReactComponent as CarretIcon } from '../../../../../assets/carret.svg';
import protection from '../../../../../assets/protection-logo.png';
import tuv from '../../../../../assets/tuv-logo.png';

const FunctionSection: React.FC = () => {
  const [descriptionShown, setDescriptionShown] = useState(false);
  const toggleExpand = () => {
    setDescriptionShown(!descriptionShown);
  };

  return (
    <Container>
      <div className='descriptions'>
        <div className='header' data-testid='header-clickable' onClick={() => toggleExpand()}>
          <p className='title-text'>So funktioniert&apos;s</p>
          <div className='title-dropdown'>
            <CarretIcon className={`dropdown-icon ${descriptionShown ? 'open' : ''}`} />
          </div>
        </div>
        {descriptionShown ? (
          <div className='body' data-testid='body-div'>
            <p className='title'>Bester Kontoumzug Deutschlands</p>
            <p className='sub-title'>
              Unkompliziert alle Zahlungspartner und Daueraufträge auf Ihr neues Girokonto umziehen.
            </p>
            <div className='bullets'>
              <div className='line'>
                <DacIcon1 />
                <p>Verbinden Sie sich mit Ihrem alten Girokonto, das Sie umziehen möchten.</p>
              </div>
              <div className='line'>
                <DacIcon2 />
                <p>
                  Anhand Ihrer Kontoumsätze identifizieren wir relevante Zahlungspartner und
                  Daueraufträge für Ihren Kontoumzug.
                </p>
              </div>
              <div className='line'>
                <DacIcon3 />
                <p>Abschließend können Sie mit nur einem Klick Ihren Kontoumzug durchführen.</p>
              </div>
            </div>
            <div className='trust'>
              <p>
                Der Login erfolgt <strong>verschlüsselt</strong> und ist{' '}
                <strong>TÜV geprüft</strong>. Ihre Login Daten werden nicht gespeichert.
              </p>

              <img src={tuv} alt='' />
              <img src={protection} alt='' />
            </div>
          </div>
        ) : null}
      </div>
    </Container>
  );
};
export default FunctionSection;
