import React, { useEffect, useState } from 'react';
import './index.scss';

import { ReactComponent as CarretIcon } from '../../../assets/carret.svg';

interface ToggleProps {
  text: string;
  onClick?: () => void;
  expandProp?: boolean;
  children?: React.ReactNode;
}

const Toggle = ({ expandProp = false, text, onClick, children }: ToggleProps) => {
  const [expand, setExpand] = useState(expandProp);

  useEffect(() => {
    setExpand(expandProp);
  }, [expandProp]);

  const handleClick = () => {
    setExpand(!expand);
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className='toggle'>
      <div onClick={handleClick} className='toggle-click' data-testid='toggle-click'>
        <div>{text}</div>
        <CarretIcon className={expand ? 'rotate-0' : 'rotate-180'} />
      </div>
      {children && expand && <div className='toggle-body'>{children}</div>}
    </div>
  );
};

export default Toggle;
