/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import './index.scss';

interface CheckboxProps {
  checked: boolean;
  inputId: string;
  disabled?: boolean;
  onChange?: (v: boolean) => void;
}

const Checkbox = ({ checked, inputId, disabled, onChange }: CheckboxProps) => {
  const handleChange = (e: any) => {
    if (onChange) {
      onChange(e.target.checked);
    }
  };

  return (
    <div className='checkbox-container' data-testid='checkbox-container'>
      <input
        type='checkbox'
        id={inputId}
        disabled={disabled}
        checked={!disabled && checked}
        onChange={handleChange}
        data-testid='checkbox-input-field'
      />
      <label htmlFor={inputId} />
    </div>
  );
};

export default Checkbox;
