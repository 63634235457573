export enum EnvironmentEnum {
  LOCAL = 'local',
  INTEGRATION = 'int',
  STAGING = 'staging',
  PROD = 'prod',
  PRODUCTION = 'production',
}

export const isProd = () =>
  process.env.REACT_APP_ENV === EnvironmentEnum.PRODUCTION ||
  process.env.REACT_APP_ENV === EnvironmentEnum.PROD;
