import React from 'react';
import './index.scss';

interface TooltipWithImgProps {
  children?: React.ReactNode;
  image: string;
}

const TooltipWithImg = ({ children, image }: TooltipWithImgProps) => (
  <div className='tooltipWithImg-wrapper'>
    <img className='img' src={image} alt='' />
    <div className='desktop-only tooltip'>{children}</div>
  </div>
);

export default TooltipWithImg;
