import React from 'react';
import './index.scss';

interface ButtonProps {
  isDisabled?: boolean;
  onClick?: () => void;
  label: string;
  classNames?: string;
  loading?: boolean;
}

export const Button = ({ isDisabled, onClick, label, classNames, loading }: ButtonProps) => {
  const disabled = isDisabled || loading ? 'disabled' : '';
  return (
    <button
      type='button'
      className={`cta-button fill ${disabled} ${classNames ?? ''}`}
      disabled={isDisabled || loading}
      onClick={onClick}
      data-testid='button-test'
    >
      {label}
      {loading && <div className='spinner' />}
    </button>
  );
};

export default Button;
