import React from 'react';
import { formatDate } from '../../../../../../../utils/helper';
import './index.scss';
import { useOverview } from '../../../../../../../contexts/OverviewContext/OverviewContext';

const AccountClosing = () => {
  const { overviewData } = useOverview();
  const { closingData } = overviewData!;

  return (
    <div className='account-closing'>
      <div className='title'>Ja, zum {formatDate(closingData.accountClosingDate)}</div>
      <div className='info'>Restsaldo wird verrechnet</div>
    </div>
  );
};

export default AccountClosing;
