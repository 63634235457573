import React, { useCallback, useMemo, useState } from 'react';
import { ToastStateContext } from './ToastStateContext';
import { IToastStateProvider, ToastType } from './ToastState.types';
import ToastBox from '../../components/Common/ToastBox';

const ToastStateProvider: React.FC<Partial<IToastStateProvider>> = ({
  removeToast: removeToastMock,
  setToastList: setToastListMock,
  children,
}) => {
  const [toasts, setToasts] = useState<ToastType[]>([]);

  const setToastList = useCallback((newToast: ToastType) => {
    setToasts((prevToasts) => [newToast, ...prevToasts.slice(0, 2)]);
  }, []);

  const removeToast = useCallback((key: string) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== key));
  }, []);

  const initialProvider: IToastStateProvider = useMemo(
    () => ({
      toasts,
      setToastList: setToastListMock || setToastList,
      removeToast: removeToastMock || removeToast,
    }),
    [toasts, removeToast],
  );

  return (
    <ToastStateContext.Provider value={initialProvider}>
      <>
        {toasts.length > 0 && (
          <div className='toast-wrapper'>
            {toasts.slice(0, 3).map((element) => (
              <ToastBox
                id={element.id}
                partnerName={element.partnerName}
                key={element.id}
                isRemoved={element.isRemoved}
                type={element.type}
              />
            ))}
          </div>
        )}

        {children}
      </>
    </ToastStateContext.Provider>
  );
};

export default ToastStateProvider;
