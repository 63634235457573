import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ServiceGateway from '../../../services/ServiceGateway';
import './index.scss';
import { ValidationResult } from '../../../utils/validators';
import Container from '../../Common/Container';
import ContactCard from '../../Common/ContactCard';
import Button from '../../Common/Button';
import DateInput from '../../Common/DateInput';

interface BirthdayCheckProps {
  updateState: () => void;
}

const BirthdayCheck = ({ updateState }: BirthdayCheckProps) => {
  const [searchParams] = useSearchParams();
  const kwsProcessId = searchParams.get('id');

  const [birthday, setBirthday] = useState('');
  const [birthdayValidation, setBirthdayValidation] = useState<ValidationResult>({
    valid: true,
  });

  const submitCheck = () => {
    if (birthdayValidation.valid && birthday) {
      ServiceGateway.attach(kwsProcessId!, birthday).then((result) => {
        if (result.data) {
          updateState();
        } else {
          setBirthdayValidation({
            valid: false,
            message: 'Bitte geben Sie Ihr Geburtsdatum an.',
          });
        }
      });
    } else {
      setBirthdayValidation({
        valid: false,
        message: 'Bitte geben Sie ein gültiges Geburtsdatum an.',
      });
    }
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      submitCheck();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  const errorCheck = !(birthday && birthdayValidation?.valid);
  return (
    <div className='birthday-check'>
      <Container>
        <h2>Sicherheitsfrage</h2>
        <p>Um Ihre Identität zu bestätigen, geben Sie bitte Ihr Geburtsdatum ein.</p>
        <div className='date-row'>
          <DateInput
            value={birthday}
            onChange={setBirthday}
            validation={birthdayValidation}
            onError={setBirthdayValidation}
          />
        </div>

        <Button
          onClick={submitCheck}
          label='Weiter'
          isDisabled={errorCheck}
          //   loading={loading}
        />
      </Container>
      <ContactCard />
    </div>
  );
};

export default BirthdayCheck;
