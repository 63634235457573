import React from 'react';
import './index.scss';

interface ContainerProps {
  children?: React.ReactNode;
  classNames?: string;
}

const Container = ({ children, classNames }: ContainerProps): JSX.Element => (
  <div className={`container ${classNames || ''}`}>{children}</div>
);

export default Container;
