import React from 'react';
import './index.scss';
import Container from '../../../../Common/Container';
import { CustomerDataModel } from '../../../../../types/Overview/Common';
import { OverviewBankModel } from '../../../../../types/Overview/ClosingDataModel';
import Kontoumzug from '../../../../../assets/kontoumzug.png';
import BankCard from '../../../../Common/BankCard';
import { isDesktopOrTablet } from '../../../../../utils/helper';

interface WelcomeSectionProps {
  customerData: Partial<CustomerDataModel>;
  newBank: OverviewBankModel;
}

const WelcomeSection: React.FC<WelcomeSectionProps> = ({
  customerData,
  newBank,
}: WelcomeSectionProps) => (
  <Container>
    <div className='welcome-section-dac' data-testid='welcome-section-dac'>
      <div>
        <p className='title'>{`${
          isDesktopOrTablet()
            ? 'Herzlich Willkommen zum CHECK24 Kontoumzug,'
            : 'Herzlich Willkommen,'
        }`}</p>
        <p className='subtitle'>{`${customerData.firstName} ${customerData.lastName}`}</p>
        <p className='text'>Kontoumzug auf Ihr neues Konto</p>
      </div>
      <img src={Kontoumzug} className='kontoumzug-logo' alt='' />
    </div>
    <BankCard name={newBank.name} iban={newBank.iban} logoName={newBank.logoName} />
  </Container>
);
export default WelcomeSection;
