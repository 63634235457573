import Cookies from 'js-cookie';

export const generatePdfLink = (link: string, appPdfDownload: string) => {
  const c24BankCookie = Cookies.get('c24bank-session');

  // appPdfDownload for giro to understand this link will be downloaded
  return `${link}?appPdfDownload=${appPdfDownload}${
    c24BankCookie ? `&token=${c24BankCookie}` : ''
  }`;
};
