export enum TimelineStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum TimelineTypeEnum {
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
  ACCOUNT_TRANSFER_SUMMARY = 'ACCOUNT_TRANSFER_SUMMARY',
  RECEIVED_IBAN_STATUS = 'RECEIVED_IBAN_STATUS',
  PAYMENT_PARTNER_TRANSACTIONS = 'PAYMENT_PARTNER_TRANSACTIONS',
  STANDING_ORDER_TRANSACTIONS = 'STANDING_ORDER_TRANSACTIONS',
  ACCOUNT_CLOSING = 'ACCOUNT_CLOSING',
}

export interface TimelineLink {
  url: string;
  description: string;
}

export interface TimelineDetail {
  name: string;
  description?: string;
  link?: TimelineLink;
  status: TimelineStatusEnum;
}

export interface TimelineStepModel {
  type: TimelineTypeEnum;
  name: string;
  description: string;
  link?: TimelineLink;
  status: TimelineStatusEnum;
  details?: TimelineDetail[];
}

export interface Timeline {
  steps: TimelineStepModel[];
}
