import React from 'react';
import './index.scss';

import Container from '../../../Common/Container';
import StandingOrder from './components/StandingOrder';
import { isApprovedState, setLastScrollPosition } from '../../../../utils/helper';
import { useKwsState } from '../../../../contexts/KwsStateContext/KwsStateContext';
import Tooltip from '../../../Common/Tooltip';
import { useOverview } from '../../../../contexts/OverviewContext/OverviewContext';
import InnerBorderContainer from '../../../Common/InnerBorderContainer';
import AddButton from '../../../Common/AddButton';
import { TooltipStateEnum } from '../../../../contexts/TooltipStateContext/Tooltip.types';
import { StandingOrderModel } from '../../../../types/Overview/StandingOrderModel';

const StandingOrdersSection = () => {
  const { setStandingOrderToProcess } = useKwsState();
  const { overviewData } = useOverview();

  const createNewStandingOrder = () => {
    setLastScrollPosition();
    setStandingOrderToProcess({
      pageType: 'ADD',
    });
  };

  const { standingOrders } = overviewData!;

  const notApprovedSO = standingOrders?.filter((so) => !isApprovedState(so.state));
  const standingOrdersAdded = notApprovedSO?.filter((so: StandingOrderModel) => so.added);
  const standingOrdersNotAdded = notApprovedSO?.filter((so: StandingOrderModel) => !so.added);

  return (
    <Container>
      <div className='orders-section'>
        <div className='header'>
          <div className='header-block'>
            <h2>Auswahl der Daueraufträge</h2>
            <Tooltip tooltipSectionName={TooltipStateEnum.STANDING_ORDER}>
              {standingOrdersNotAdded?.length > 0 ? (
                <>
                  <p>
                    Wir haben aus Ihrem Kontoauszug die folgenden Daueraufträge ausgelesen und für
                    den Umzug ausgewählt.
                  </p>
                  <br />
                  <p>Über die Checkboxen können Sie die Auswahl anpassen.</p>
                  <br />
                  <p>
                    Die ausgewählten Daueraufträge werden vom bisherigen Konto gelöscht und im neuen
                    Konto eingerichtet, sodass sie ohne Unterbrechung oder Überlappung ausgeführt
                    werden können.
                  </p>
                </>
              ) : (
                <p>
                  Fügen Sie hier Daueraufträge hinzu, die Sie auf Ihr neues Konto umziehen möchten.
                  Wir erledigen den Rest und richten diese auf Ihrem neuen Konto ein.
                </p>
              )}
            </Tooltip>
          </div>
          {standingOrdersNotAdded?.length > 0 ? (
            <p>
              Welche Daueraufträge sollen auf Ihrem neuen Konto eingerichtet und von Ihrem
              bisherigen Konto gelöscht werden?
            </p>
          ) : (
            <p>Welche Daueraufträge sollen auf Ihrem neuen Konto eingerichtet werden?</p>
          )}
        </div>
        <InnerBorderContainer>
          {standingOrdersNotAdded?.length > 0 && (
            <div className='orders'>
              {standingOrdersNotAdded.map((t: StandingOrderModel) => (
                <StandingOrder key={t.id} standingOrder={t} />
              ))}
            </div>
          )}
        </InnerBorderContainer>
        {standingOrdersAdded?.length > 0 && (
          <div className='recent-section'>
            <div className='header'>Manuell hinzugefügt</div>
            <InnerBorderContainer>
              {overviewData && (
                <div>
                  <div className='notifications'>
                    {standingOrdersAdded.map((t: StandingOrderModel) => (
                      <StandingOrder key={t.id} standingOrder={t} />
                    ))}
                  </div>
                </div>
              )}
            </InnerBorderContainer>
          </div>
        )}

        <div className='add-order'>
          <AddButton onClick={createNewStandingOrder} title='Dauerauftrag hinzufügen' />
        </div>
      </div>
    </Container>
  );
};

export default StandingOrdersSection;
