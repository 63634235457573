/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { MutableRefObject, useRef, useState, useEffect } from 'react';
import SignaturePad from 'react-signature-canvas';
import Container from '../../Common/Container';
import ServiceGateway, { logoutSystem } from '../../../services/ServiceGateway';
import ContactCard from '../../Common/ContactCard';
import { useKwsState } from '../../../contexts/KwsStateContext/KwsStateContext';
import './index.scss';
import { ReactComponent as FileIcon } from '../../../assets/file.svg';
import { ReactComponent as BinIcon } from '../../../assets/bin.svg';
import {
  formatDate,
  isDesktopOrTablet,
  scrollToPosition,
  setLastScrollPosition,
} from '../../../utils/helper';
import Tooltip from '../../Common/Tooltip';
import { generatePdfLink } from '../../../utils/linkGenerator';
import ActionButtons from '../../Common/ActionButtons';
import { TooltipStateEnum } from '../../../contexts/TooltipStateContext/Tooltip.types';
import ResetButton from '../../Common/ResetButton';
import { SignaturePageModel } from '../../../types/SignaturePage/SignaturePageModel';
import TransferBankCard from '../../Common/TransferBankCard';
import { useFrontendState } from '../../../contexts/FrontendRuleContext/FrontendRuleContext';
import { FrontEndSection } from '../../../contexts/FrontendRuleContext/FrontendRule.types';

const initialBank = {
  name: '',
  iban: '',
  accountHolderName: '',
  bic: '',
  balance: '',
  logoName: '',
};

const initialState: SignaturePageModel = {
  globalDate: {
    globalSwitchingDate: '',
    accountClosingDate: '',
    allSwitchingDatesMatch: true,
    accountClosingOnly: false,
  },
  newBank: initialBank,
  oldBank: initialBank,
};

const DigitalSignature = () => {
  const { decideSectionToShow } = useFrontendState();
  const signRef = useRef() as MutableRefObject<SignaturePad>;
  const [isSigned, setSigned] = useState(false);
  const [isCtaLoading, setIsCtaLoading] = useState(false);
  const [isGoBackLoading, setIsGoBackLoading] = useState(false);
  const { kwsState, refreshKwsState } = useKwsState();
  const oldWidth = useRef<number>();
  const requestCounter = useRef(0);
  const [isPoaLoading, setIsPoaLoading] = useState(false);
  const [dacPageData, setDacPageData] = useState<SignaturePageModel>(initialState);
  const { globalDate } = dacPageData;

  const handleClick = () => {
    setLastScrollPosition(0);
    sessionStorage.removeItem('c24-partners');
    setIsCtaLoading(true);
    const canvas = signRef.current.getTrimmedCanvas();
    canvas.toBlob((blob) => {
      const imageFile = new File([blob as Blob], 'signature.png', {
        type: 'image/png',
      });

      ServiceGateway.sign(
        kwsState!.id,
        window.wf_deviceoutput,
        navigator.userAgent,
        imageFile,
      ).then((result) => {
        const isSuccess = result.status >= 200 && result.status < 300;
        if (isSuccess) {
          refreshKwsState(undefined, () => setIsCtaLoading(false));
        }
      });
    }, 'image/png');
  };

  const downloadPowerOfAttorney = async () => {
    if (requestCounter?.current >= 20) {
      requestCounter.current = 0;
      setIsPoaLoading(false);
      return;
    }
    setIsPoaLoading(true);
    const result = await ServiceGateway.downloadPowerOfAttorney(kwsState!.id);
    const isSuccess = result.status >= 200 && result.status < 300;

    if (isSuccess && result.headers['content-type'] === 'application/pdf') {
      const url = generatePdfLink(`/frontend/${kwsState!.id}/powerOfAttorney`, 'powerOfAttorney');
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      document.body.appendChild(link);
      setTimeout(() => {
        link.click();
        setTimeout(() => {
          link.remove();
        }, 10);
      }, 10);

      requestCounter.current = 0;
      setIsPoaLoading(false);
    } else {
      requestCounter.current += 1;
      setTimeout(() => {
        downloadPowerOfAttorney();
      }, 1000);
    }
  };

  const rescaleSignature = (data: any, scale: number): any => {
    if (!data) {
      return [];
    }
    return data.map((lines: any) =>
      lines.map((line: any) => ({
        ...line,
        x: line.x * scale,
      })),
    );
  };

  function resizeCanvas() {
    if (signRef?.current) {
      const signaturePad = signRef.current;
      const canvas = signaturePad.getCanvas();

      const ratio = oldWidth.current ? canvas.offsetWidth / oldWidth.current : 1;
      const ratioForScale = window.devicePixelRatio || 1;

      const data = signaturePad.toData();
      const rescaledSignature = rescaleSignature(data, ratio);

      canvas.width = canvas.offsetWidth * ratioForScale;
      canvas.height = canvas.offsetHeight;

      signaturePad.clear();
      canvas.getContext('2d')?.scale(ratioForScale, 1);
      signaturePad.fromData(rescaledSignature);

      oldWidth.current = canvas.offsetWidth;
    }
  }

  const handleClear = () => {
    if (isSigned) {
      resizeCanvas();
      signRef.current.clear();
      setSigned(false);
    }
  };

  const resizeAction = () => setTimeout(resizeCanvas, 0);
  useEffect(() => {
    window.addEventListener('resize', resizeAction);
    return () => {
      window.removeEventListener('resize', resizeAction);
    };
  });

  useEffect(() => {
    if (signRef?.current) {
      if (isCtaLoading) {
        signRef.current.off();
      } else {
        signRef.current.on();
      }
    }
  }, [isCtaLoading]);

  const getSignaturePageData = async () => {
    const result = await ServiceGateway.getSignaturePage(kwsState!.id);
    setDacPageData(result.data);
  };

  useEffect(() => {
    getSignaturePageData();

    scrollToPosition('0');
    resizeCanvas();
  }, []);

  const goBack = () => {
    if (!isGoBackLoading) {
      setIsGoBackLoading(true);
      ServiceGateway.backOnEditing(kwsState!.id).then(() => {
        refreshKwsState(undefined, () => setIsGoBackLoading(false));
      });
    }
  };

  const accountTransferText = `Ihr Kontoumzug ${
    globalDate.allSwitchingDatesMatch ? ' zum' : ' ab'
  } ${formatDate(globalDate.globalSwitchingDate!)}`;

  return (
    <div className='digital-signature' data-testid='digital-signature'>
      <ResetButton title='zurück zur Übersicht' onClick={goBack} isLoading={isGoBackLoading} />

      {decideSectionToShow(FrontEndSection.WELCOME) && (
        <Container>
          <div>
            <h2>
              {globalDate?.accountClosingOnly
                ? `Ihre Kontoschließung zum ${formatDate(globalDate.accountClosingDate!)}`
                : accountTransferText}
            </h2>
            <TransferBankCard oldBank={dacPageData.oldBank} newBank={dacPageData.newBank} />
          </div>
        </Container>
      )}

      {decideSectionToShow(FrontEndSection.DIGITAL_SIGNATURE) && (
        <Container>
          <div className='signature-header'>
            <h2>Auftrag zum Kontoumzug erteilen</h2>
            <Tooltip tooltipSectionName={TooltipStateEnum.DIGITAL_SIGNATURE}>
              <p className='title'>Nutzung Ihrer Unterschrift</p>
              <p>
                Mit Ihrer Unterschrift bestätigen Sie den Auftrag zum Kontoumzug. Wir benötigen Ihre
                Unterschrift um Lastschriften und Daueraufträge auf Ihrem neuen Konto einzurichten,
                relevante Zahlungspartner über Ihren Kontoumzug zu informieren und die Schließung
                Ihres bisherigen Kontos zu veranlassen, falls Sie dies ausgewählt haben.
              </p>
            </Tooltip>
          </div>
          <div className='row-1'>
            <p>
              Mit Ihrer Unterschrift ermächtigen Sie uns, in Ihrem Namen den Kontoumzug
              durchzuführen. Bitte unterschreiben Sie wie auf Ihrem Ausweisdokument.
            </p>
          </div>

          <div
            className={`poa${isPoaLoading ? ' disabled' : ''}`}
            onClick={() => {
              if (!isPoaLoading) {
                downloadPowerOfAttorney();
              }
            }}
            data-testid='download-button'
          >
            <FileIcon className='file-icon' />
            Auftrag zum Kontoumzug
            {isPoaLoading && <div className='spinner' />}
          </div>

          <p>
            Unterschreiben Sie hier mit{' '}
            {isDesktopOrTablet() ? <span>Ihrer Maus</span> : <span>Ihrem Finger</span>}:
          </p>

          <div className='signature-pad-container'>
            <div className='circle-wrapper' onClick={handleClear}>
              <BinIcon className={`bin-icon ${isSigned ? 'active' : ''}`} />
            </div>
            <SignaturePad
              canvasProps={{ className: 'signature-pad' }}
              ref={signRef}
              onEnd={() => {
                setSigned(true);
              }}
              clearOnResize={false}
            />
            <div className='dashed-spaced' />
          </div>
        </Container>
      )}

      {decideSectionToShow(FrontEndSection.CTA_BUTTON) && (
        <ActionButtons
          isDisabled={!isSigned}
          isLoading={isCtaLoading}
          proceedButton={{ label: 'Kontoumzug beauftragen', onClick: handleClick }}
          cancelButton={{ label: 'speichern und abmelden', onCancel: logoutSystem }}
        />
      )}

      {decideSectionToShow(FrontEndSection.CONTACT_CARD) && <ContactCard />}
    </div>
  );
};

export default DigitalSignature;
