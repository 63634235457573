import React from 'react';
import './index.scss';

export interface RadioProps {
  value: any;
  name: string;
  disabled?: boolean;
  className?: string;
  label: string;
  onChange: (val: any) => void;
  checked: boolean;
}

const Radio = ({ value, name, disabled, className, label, onChange, checked }: RadioProps) => {
  const handleChange = (e: any) => {
    const val = e.currentTarget.value;
    onChange(val);
  };

  const onClick = (id: string) => {
    document.getElementById(id)?.click();
  };

  const id = `radio-${value}`;
  return (
    <div className={`radio-button-container ${className ?? ''}`} onClick={() => onClick(id + name)}>
      <input
        type='radio'
        disabled={disabled}
        name={name}
        id={id + name}
        onChange={handleChange}
        value={value}
        checked={checked}
      />
      <label htmlFor={id + name}>{label}</label>
    </div>
  );
};

export default Radio;
