import React from 'react';
import './index.scss';

interface KwsBodyProps {
  children?: React.ReactNode;
}

const KwsBody = ({ children }: KwsBodyProps) => (
  <div className='kws-body' data-testid='kws-body'>
    {children}
  </div>
);

export default KwsBody;
