import React, { useState } from 'react';
import Container from '../../../Common/Container';
import './index.scss';
import QA from './QA';
import { DeviceOutputEnum } from '../../../../utils/deviceOutput';

const FaqSection = () => {
  const [showAll, setShowAll] = useState(false);
  const qasArray = [
    {
      id: 1,
      question: 'Wie kann ich den Kontoumzug nutzen?',
      answer: (
        <p>
          Nachdem Sie Ihr neues Girokonto über CHECK24 beantragt und sich anschließend legitimiert
          haben, erhalten Sie automatisch eine E-Mail mit Zugang zum Kontoumzug. Sollten Sie sich
          per VideoIdent legitimieren, können Sie direkt danach mit dem Kontoumzug fortfahren.
          Weiterhin können Sie den Kontoumzug jederzeit über Ihren CHECK24 Kundenbereich starten.
          Als Kunde der C24 Bank finden Sie den Zugang direkt in Ihrer Banking App.
        </p>
      ),
      opened: false,
    },
    {
      id: 12,
      question: 'Ist die Anmeldung mit meinem Altkonto innerhalb des Kontoumzugs sicher?',
      answer: (
        <p>
          Die Anmeldung in Ihrem Altkonto ist sicher. Ihre Daten werden ausschließlich in
          verschlüsselter Form an Ihre Bank übermittelt, um Ihnen relevante Zahlungspartner und
          Daueraufträge für Ihren Kontoumzug vorzuschlagen. Ihre Log-In Daten werden von CHECK24
          nicht gespeichert. Sensible Daten wie Benutzername oder Passwort sind für CHECK24 zu
          keinem Zeitpunkt einsehbar. Der Kontozugriff ist TÜV-zertifiziert und durch die PSD2 (2.
          Europäische Zahlungsdiensterichtlinie) auf europäischer Ebene und durch das ZAG
          (Zahlungsdiensteaufsichtsgesetz) auf nationaler Ebene reguliert.
        </p>
      ),
      opened: false,
    },
    {
      id: 19,
      question: 'Wie funktioniert der manuelle Kontoumzug ohne Online-Banking Anmeldung?',
      answer: (
        <p>
          Der CHECK24 Kontoumzug bietet Ihnen maximale Flexibilität und Kontrolle. Sie entscheiden,
          ob Sie den automatischen oder den manuellen Kontoumzug ohne Online-Banking Anmeldung
          nutzen möchten. Beim automatischen Kontoumzug haben Sie den Vorteil, dass Sie sich mit
          Ihrem bisherigen Bankkonto verbinden und wir somit Ihre Zahlungspartner und Daueraufträge
          automatisch für die Benachrichtigung vorbereiten können. Beim manuellen Kontoumzug müssen
          Sie Ihre Zahlungspartner selbst erfassen. In beiden Fällen übernimmt CHECK24 die
          Benachrichtigung über die Änderung Ihrer Bankverbindung.
        </p>
      ),
      opened: false,
    },
    {
      id: 13,
      question:
        'Was passiert mit den Transaktionsdaten meines Altkontos, die für den Kontoumzug analysiert werden?',
      answer: (
        <p>
          Erteilen Sie die Einwilligung für die Nutzung des Kontoinformationsdienstes der Tink
          Germany GmbH, so werden die Daten Ihres Altkontos einmalig abgerufen und der CHECK24
          Vergleichsportal Karten und Konten GmbH zur Verfügung gestellt. Die Transaktionsdaten
          Ihres Altkontos werden von uns gemäß Art. 13 EU-DSGVO verarbeitet, gespeichert und
          ausschließlich zu Zwecken des Kontoumzugs genutzt. Dies ist notwendig, um Ihnen relevante
          Zahlungspartner und Daueraufträge für den Kontoumzug vorzuschlagen. Weitere Informationen
          finden Sie unter{' '}
          <a
            href='https://finanzen.check24.de/download/kartenundkonten/vorab-information.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Vorab-Informationen zum Datenschutz
          </a>
          .
        </p>
      ),
      opened: false,
    },
    {
      id: 18,
      question: 'Welche Banken nehmen nicht am Kontoumzug teil?',
      answer: (
        <p>
          Vereinzelte wenige Banksysteme werden nicht vom Kontoumzug unterstützt. Steht Ihre
          bisherige Bank nicht zur Auswahl, nutzen Sie bitte den manuellen Kontoumzug. Dadurch
          können Sie auch ohne die Verbindung zu Ihrer bisherigen Bank Zahlungspartner selbstständig
          zu Ihrem Kontoumzug hinzufügen und optional die Schließung Ihres alten Kontos beauftragen.
        </p>
      ),
      opened: false,
    },
    {
      id: 2,
      question: 'Kann ich den Kontoumzug unterbrechen und später fortsetzen?',
      answer: (
        <p>
          Möchten Sie den Kontoumzug später fortsetzen, klicken Sie einfach auf &quot;Speichern und
          abmelden&quot;. Ihr bisheriger Fortschritt wird gespeichert und Sie können den Kontoumzug
          jederzeit fortsetzen.
        </p>
      ),
      opened: false,
    },
    {
      id: 3,
      question:
        'Kann ich nach Durchführung des Kontoumzugs noch weitere Zahlungspartner und Daueraufträge umziehen?',
      answer: (
        <p>
          Sie können auch nach Durchführung des Kontoumzugs weitere Zahlungspartner über Ihre neue
          Bankverbindung informieren und Daueraufträge umziehen. Starten Sie den Kontoumzug hierzu
          einfach erneut und wählen Sie im Bereich &quot;Kontoumzug in mehreren Etappen?&quot;
          weitere Umzugsschritte aus. Bestätigen Sie dies anschließend erneut mit &quot;Kontoumzug
          durchführen&quot;.
        </p>
      ),
      opened: false,
    },
    {
      id: 4,
      question: 'Welche Zahlungspartner kann ich auf mein neues Konto umziehen?',
      answer: (
        <p>
          Grundsätzlich können fast alle Zahlungspartner, die Geldeingänge auf Ihrem Konto
          gutschreiben oder Lastschriften auf Ihrem Konto belasten, umgezogen werden. Somit können
          Sie z. B. Ihren Mobilfunkanbieter oder Ihren Arbeitgeber bequem über Ihre neue
          Bankverbindung informieren. Sollte der Kontoumzug für bestimmte Zahlungspartner nicht
          möglich sein, werden Sie darüber innerhalb des Kontoumzugs informiert.
        </p>
      ),
      opened: false,
    },
    {
      id: 5,
      question: 'Kann ich den Kontoumzug für ein Gemeinschaftskonto nutzen?',
      answer: (
        <p>
          Sie können den Kontoumzug nutzen, um ein Einzelkonto auf Ihr neu abgeschlossenes
          Gemeinschaftskonto umzuziehen. Umgekehrt kann ein Gemeinschaftskonto derzeit nicht für den
          Umzug auf ein neues Einzel- oder Gemeinschaftskonto genutzt werden.
        </p>
      ),
      opened: false,
    },
    {
      id: 6,
      question: 'Wie viele Altkonten kann ich auf mein neues Girokonto umziehen?',
      answer: (
        <p>
          Derzeit unterstützt nur die C24 Bank den Umzug von mehreren Altkonten auf ein neues C24
          Bank Konto. Sollten Sie Ihr neues Konto bei einer anderen Bank eröffnet haben, können Sie
          nur ein Altkonto auf das neue Konto umziehen. Wir arbeiten derzeit daran, den Umzug von
          mehreren Altkonten für alle Kunden zu ermöglichen.
        </p>
      ),
      opened: false,
    },
    {
      id: 7,
      question: 'Wie lange ist der Link zum Kontoumzug gültig?',
      answer: (
        <p>
          Sie können den Kontoumzug bis zu 180 Tage nach dem ersten Klick auf den Einladungslink
          nutzen.
        </p>
      ),
      opened: false,
    },
    {
      id: 8,
      question:
        'Bei welchen Banken können die bestehenden Daueraufträge meines Altkontos direkt auf mein neues Konto übertragen werden?',
      answer: (
        <p>
          Derzeit unterstützen folgende Banken die automatische Übertragung von Daueraufträgen: C24
          Bank, DKB und HypoVereinsbank. Sollten Sie Ihr neues Konto bei einer anderen Bank eröffnet
          haben, werden Sie innerhalb des Kontoumzugs zur selbstständigen Einrichtung der
          Daueraufträge gebeten.
        </p>
      ),
      opened: false,
    },
    {
      id: 9,
      question:
        'Bei welchen Banken wird die IBAN meines neuen Kontos direkt an den Kontoumzug übertragen?',
      answer: (
        <p>
          Zur Ausführung des Kontoumzugs benötigen wir immer die IBAN Ihres neuen Kontos. Bei
          folgenden Banken erhalten wir die IBAN automatisch: 1822direkt, C24 Bank, HypoVereinsbank,
          Norisbank, Postbank und PSD Bank Nürnberg. Sollten Sie Ihr neues Konto bei einer anderen
          Bank eröffnet haben, können Sie die IBAN nach Unterschrift und Bestätigung des Kontoumzugs
          selbst eingeben.
        </p>
      ),
      opened: false,
    },
    {
      id: 10,
      question: 'Kann der Kontoumzug auch früher als vorgeschlagen durchgeführt werden?',
      answer: (
        <p>
          Zahlungspartner benötigen bis zu 6 Wochen Vorlaufzeit, um die Änderung der Bankverbindung
          für Sie durchzuführen. Es ist daher nicht möglich, den Kontoumzug früher als vorgeschlagen
          durchzuführen.
        </p>
      ),
      opened: false,
    },
    {
      id: 11,
      question:
        'Warum ist die Kündigung des Kontos nicht zeitgleich mit dem Umzug von Zahlungspartnern und Daueraufträgen möglich?',
      answer: (
        <p>
          Die Kündigung der alten Bank kann frühestens 4 Wochen nach dem Umzug des letzten
          Zahlungspartners oder Dauerauftrags zum 15. oder Ende des Monats durchgeführt werden. Nur
          so können wir einen reibungslosen Umzug gewährleisten und eventuelle Rücklastschriften für
          Sie vermeiden.
        </p>
      ),
      opened: false,
    },
    {
      id: 14,
      question: 'Ich habe Probleme bei der Anmeldung in meinem Altkonto. Was kann ich tun?',
      answer: (
        <p>
          Bitte kontaktieren Sie unser Experten-Team per E-Mail oder Telefon. Die Kontaktdaten
          finden Sie am Ende dieser Seite.
        </p>
      ),
      opened: false,
    },
    {
      id: 15,
      question: 'Wird mein Altkonto im Rahmen des Kontoumzugs automatisch geschlossen?',
      answer: (
        <p>
          Die Kontoschließung ist optional. Auf Wunsch schließen wir Ihr altes Konto zum von Ihnen
          selbst gewählten Termin und verrechnen den Restsaldo mit Ihrem neuen Konto.
        </p>
      ),
      opened: false,
    },
    {
      id: 16,
      question: 'Kann der Kontoumzug auch ohne Online-Banking Login genutzt werden?',
      answer: (
        <p>
          Die Anmeldung mit den Zugangsdaten für das Online-Banking Ihrer alten Bank ist notwendig,
          um Ihnen relevante Zahlungspartner und Daueraufträge für Ihren Kontoumzug vorzuschlagen.
          Durch den manuellen Kontoumzug können Sie auch ohne die Verbindung mittels Online-Banking
          Zahlungspartner selbstständig hinzufügen und optional die Schließung Ihres alten Kontos
          beauftragen.
        </p>
      ),
      opened: false,
    },
    {
      id: 17,
      question: 'Werden Unterkonten oder Tagesgeldkonten bei meiner alten Bank mit umgezogen?',
      answer: (
        <p>
          Unterkonten wie z. B. Tagesgeldkonten werden nicht automatisch über den Kontoumzug
          umgezogen. Bitte überweisen Sie das Guthaben selbstständig auf das dazugehörige
          Hauptkonto. Bei Kontoschließung wird das Unterkonto automatisch geschlossen.
        </p>
      ),
      opened: false,
    },
  ];
  const [qas, setQas] = useState(qasArray);

  const qasToShow = showAll ? qas : qas.slice(0, 3);
  const setShowAnswer = (id: number, show: boolean) => {
    if (window.wf_deviceoutput === DeviceOutputEnum.DESKTOP) {
      setQas([...qas.map((qa) => ({ ...qa, opened: qa.id === id ? show : false }))]);
    } else {
      setQas([...qas.map((qa) => ({ ...qa, opened: qa.id === id ? show : qa.opened }))]);
    }
  };

  return (
    <Container>
      <div className='faq-section'>
        <h2>Häufige Fragen</h2>
        <div className='questions'>
          {qasToShow.map((qa) => (
            <QA
              key={qa.id}
              question={qa.question}
              showAnswer={qa.opened}
              setShowAnswer={(show) => setShowAnswer(qa.id, show)}
            >
              {qa.answer}
            </QA>
          ))}
        </div>
        <div className='all-questions'>
          <span onClick={() => setShowAll(!showAll)}>
            {!showAll ? 'alle Fragen anzeigen' : 'weniger Fragen anzeigen'}
          </span>
        </div>
      </div>
    </Container>
  );
};

export default FaqSection;
