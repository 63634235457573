import React from 'react';
import './index.scss';

import Container from '../../../Common/Container';
import guarantee from '../../../../assets/guarantee.png';
import Tooltip from '../../../Common/Tooltip';
import { TooltipStateEnum } from '../../../../contexts/TooltipStateContext/Tooltip.types';
import { useKwsState } from '../../../../contexts/KwsStateContext/KwsStateContext';
import { useOverview } from '../../../../contexts/OverviewContext/OverviewContext';
import { createPrefilledMailLink } from '../../../../utils/helper';

const GuaranteeInfoSection = () => {
  const { kwsState } = useKwsState();
  const { overviewData } = useOverview();

  const { processNumber } = kwsState!;
  const newBankName = overviewData!.closingData.newBank.name;

  const mailtoLink = createPrefilledMailLink(processNumber, newBankName);

  return (
    <Container>
      <div className='guarantee-info-section' data-testid='guarantee-info-section'>
        <div className='header'>
          <img src={guarantee} alt='' />
          <h2>CHECK24 Kontoumzug mit 100% Sorglos-Garantie</h2>
          <Tooltip tooltipSectionName={TooltipStateEnum.GUARANTEE_SECTION}>
            <p>
              Wir garantieren Ihnen bei Nutzung des CHECK24 Kontoumzugs einen reibungslosen Ablauf
              Ihres Kontoumzugs. Sollten Ihnen dabei dennoch unerwartete Kosten entstehen, so
              übernimmt CHECK24 diese Kosten für Sie, sofern der Zahlungspartner von Ihnen im Rahmen
              des CHECK24 Kontoumzugs informiert wurde. Dies beinhaltet Mahnkosten aufgrund
              mangelnder Deckung des Altkontos (nur Kosten für die erstmalige Mahnung) sowie Kosten
              für Lastschriftrückbuchungen. Insgesamt werden bis zu 50 € pro Kunde ausgeglichen.
              Hierzu schicken Sie bitte die Rechnung der Kosten unter Angabe Ihrer IBAN an:{' '}
              <a href={mailtoLink} target='_blank' rel='noreferrer'>
                kontoumzug@check24.de
              </a>
            </p>
          </Tooltip>
        </div>
        <div className='text'>
          Mit dem CHECK24 Kontoumzug versprechen wir Ihnen einen reibungslosen Wechsel auf Ihr neues
          Girokonto.
          <br /> <br />
          Sollten Ihnen nach der Nutzung des CHECK24 Kontoumzugs trotzdem Gebühren entstehen (z.B.
          durch Lastschriftrückbuchungen oder Mahnungen), übernimmt CHECK24 die Kosten für Sie.
          <br /> <br />
          Versprochen!
        </div>
      </div>
    </Container>
  );
};

export default GuaranteeInfoSection;
