import React, { useEffect, useState } from 'react';
import './index.scss';
import kontoumzug from '../../../assets/kontoumzug.png';
import ContactCard from '../../Common/ContactCard';
import Container from '../../Common/Container';
import { EnvironmentEnum, isProd } from '../../../utils/environment';
import { DeviceOutputEnum } from '../../../utils/deviceOutput';

interface LoginProps {
  email: string;
  loginPhone: string;
  hasSsoUserId: boolean;
  loginType: string;
}

const Login = ({ email, loginPhone, loginType, hasSsoUserId }: LoginProps) => {
  const uliVersion = '20240926-1';
  const device = window.wf_deviceoutput === DeviceOutputEnum.DESKTOP ? 'desktop' : 'mobile';

  const [scriptLoaded, setScriptLoaded] = useState(false);

  const decideSocialLoginBaseUrl = () => {
    switch (process.env.REACT_APP_ENV) {
      case EnvironmentEnum.INTEGRATION:
        return 'https://kundenbereich.check24-int.de/';
      case EnvironmentEnum.STAGING:
        return 'https://kundenbereich.check24-test.de/';
      default:
        return 'https://kundenbereich.check24.de/';
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://anmelden.check24${
      !isProd() ? '-test' : ''
    }.de/${uliVersion}/bundle_${device}WC.js`;
    script.addEventListener('load', () => {
      setScriptLoaded(true);
    });
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className='login-page' data-testid='login-page'>
      <Container>
        <div className='header'>
          <img src={kontoumzug} className='kontoumzug-logo' alt='' />
        </div>
        <div className='unified-login-section'>
          {scriptLoaded &&
            React.createElement(
              'unified-login',
              {
                api_product: 'fincrs',
                deviceoutput: device,
                login_email: email,
                login_phone: loginPhone,
                login_type: loginType,
                anonymous: 'false',
                responsive: 'true',
                main_width: '100',
                help_width: '100',
                force_login: hasSsoUserId ? 'true' : 'false',
                context: 'as',
                border: 'none',
                social_login_closing_url: `${window.location.origin}/app/google_closing_page.html`,
                social_login_base_url: decideSocialLoginBaseUrl(),
              },
              '',
            )}
        </div>
      </Container>
      <ContactCard />
    </div>
  );
};

export default Login;
