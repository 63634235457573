import React from 'react';
import './index.scss';

import { ReactComponent as CarretIcon } from '../../../../../assets/carret.svg';

interface QAProps {
  question: string;
  showAnswer: boolean;
  setShowAnswer: (show: boolean) => void;
  children?: React.ReactNode;
}

const QA = ({ question, showAnswer, setShowAnswer, children }: QAProps) => (
  <div className='qa'>
    <div className='question' onClick={() => setShowAnswer(!showAnswer)}>
      <p className={`text ${showAnswer && 'bold'}`}>{question}</p>
      <CarretIcon className={`carret ${showAnswer ? 'open' : ''}`} />
    </div>
    {showAnswer ? <div className='answer'>{children}</div> : null}
  </div>
);
export default QA;
