import React, { useState } from 'react';
import './index.scss';

import Container from '../../../../Common/Container';
import { Button } from '../../../../Common/Button';
import { ValidationResult } from '../../../../../utils/validators';
import ServiceGateway from '../../../../../services/ServiceGateway';
import { useKwsState } from '../../../../../contexts/KwsStateContext/KwsStateContext';
import Tooltip from '../../../../Common/Tooltip';
import { useOverview } from '../../../../../contexts/OverviewContext/OverviewContext';
import IbanInput from '../../../../Common/IbanInput';
import { TooltipStateEnum } from '../../../../../contexts/TooltipStateContext/Tooltip.types';
import InfoBox from '../../../../Common/InfoBox';

interface MissingIbanSectionProps {
  onSubmit: () => void;
}

const MissingIbanSection = ({ onSubmit }: MissingIbanSectionProps): JSX.Element => {
  const [iban, setIban] = useState('');
  const [validationError, setValidationError] = useState<Record<string, ValidationResult>>({});
  const { kwsState, refreshKwsState } = useKwsState();
  const { overviewData } = useOverview();
  const { closingData } = overviewData!;

  const submit = async () => {
    try {
      const result = await ServiceGateway.attachTargetIban(kwsState!.id, iban);
      const isSuccess = result.status >= 200 && result.status < 300;
      if (isSuccess) {
        sessionStorage.setItem('scrollPosition', '0');
        refreshKwsState();
        onSubmit();
      }
    } catch (error: any) {
      if (error.response?.status === 400) {
        if (error.response?.data?.errors) {
          setValidationError({
            ...validationError,
            ...error.response.data.errors.reduce((acc: any, err: any) => {
              if (err.type === 'IBAN') {
                return {
                  ...acc,
                  [err.key]: { valid: false, message: err.message },
                };
              }
              return acc;
            }, {}),
          });
        }
      }
    }
  };

  return (
    <Container>
      <div className='missing-iban-section'>
        <div className='title' data-testid='missing-iban-header'>
          <h2>Neue IBAN nachreichen</h2>

          <Tooltip tooltipSectionName={TooltipStateEnum.MISSING_IBAN}>
            <p>
              Der Kontoumzug kann erst nach Eingabe der IBAN Ihres neuen Kontos ausgeführt werden.
              Sollte Ihnen die IBAN noch nicht vorliegen, können Sie Ihren bereits erfassten
              Fortschritt über &quot;Speichern und abmelden&quot; sichern und die IBAN später
              nachtragen. Klicken Sie hierzu einfach erneut auf den Einladungslink in der E-Mail.
            </p>
          </Tooltip>
        </div>
        <InfoBox title='Wir benötigen noch Ihre neue IBAN' type='alert' />
        <div className='text' data-testid='missing-iban-text'>
          Leider hat uns die {closingData?.newBank?.name ?? 'Bank'} Ihre neue IBAN nicht automatisch
          übermittelt. Bitte geben Sie Ihre IBAN hier ein, damit Ihr Kontoumzug durchgeführt werden
          kann.
        </div>
        <div className='form-group'>
          <div className='form-input' data-testid='missing-iban-input'>
            <IbanInput
              placeholder='Neue IBAN'
              value={iban}
              onChange={(v) => setIban(v)}
              validation={validationError.iban}
            />
          </div>
          {/* CTA */}
          <div className='button' data-testid='missing-iban-submit'>
            <Button onClick={submit} label='IBAN speichern' />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MissingIbanSection;
