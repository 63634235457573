import React from 'react';
import './index.scss';

interface InnerBorderContainerProps {
  children?: React.ReactNode;
  classNames?: string;
}

const InnerBorderContainer = ({
  children,
  classNames,
}: InnerBorderContainerProps): JSX.Element | null =>
  children ? <div className={`inner-border-container ${classNames || ''}`}>{children}</div> : null;

export default InnerBorderContainer;
