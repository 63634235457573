import { useEffect, useState } from 'react';

type CursorData = {
  field?: HTMLInputElement;
  start: number;
  end: number;
};

const addSpaces = (iban: string): string => {
  const parts: string[] = [];
  for (let i = 0; i < iban.length; i += 4) {
    parts.push(iban.substring(i, i + 4));
  }
  return parts.join(' ');
};

function getSelectionRange(e: any, isDeleting: boolean) {
  let shiftCursor = 0;

  if (isDeleting) {
    shiftCursor = e.target.selectionStart % 5 === 0 ? -1 : 0;
  } else {
    shiftCursor =
      (e.target.selectionStart % 5 === 4 || e.target.selectionStart % 5 === 0) &&
      e.target.value.length !== e.target.selectionEnd
        ? 1
        : 0;
  }

  return {
    start: e.target.selectionStart + shiftCursor,
    end: e.target.selectionEnd + shiftCursor,
  };
}

export function formatIbanLook(value = ''): string {
  if (typeof value !== 'string') {
    return '';
  }

  const iban = value
    .replace(/\s\s+/g, ' ')
    .replace(/[^0-9a-zA-Z*]/gi, '')
    .toUpperCase();

  return addSpaces(iban);
}

export const handleCursorPosition = (e: any, isDeleting: boolean) => {
  if (e.target.value.length === e.target.selectionEnd) {
    e.target.value = formatIbanLook(e.target.value);
  }
  return getSelectionRange(e, isDeleting);
};

export default function (value: string) {
  const [formattedValue, setFormattedValue] = useState<string>(formatIbanLook(value));
  const [cursorData, setCursorData] = useState<CursorData>({
    start: 0,
    end: 0,
  });

  useEffect(() => {
    if (cursorData.field && typeof cursorData.field.setSelectionRange !== 'undefined') {
      cursorData.field.setSelectionRange(cursorData.start, cursorData.end, 'forward');
    }
  }, [cursorData, formattedValue]);

  const handleIbanInput = (e: any, isDeleting: boolean) => {
    const pos = handleCursorPosition(e, isDeleting);
    setCursorData({
      field: e.target,
      start: pos.start,
      end: pos.end,
    });

    e.target.value = e.target.value.split(' ').join('');
    setFormattedValue(formatIbanLook(e.target.value));
  };

  return {
    handleIbanInput,
    formattedValue,
  };
}
