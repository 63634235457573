import React from 'react';
import { formatAmount, formatDate, removeNegativeSign } from '../../../../../../../utils/helper';
import { NotificationModel } from '../../../../../../../types/Overview/NotificationModel';
import { StandingOrderModel } from '../../../../../../../types/Overview/StandingOrderModel';
import './index.scss';

interface ReviewTransactionProps {
  reviewTransaction: NotificationModel | StandingOrderModel;
}

const ReviewTransaction = ({ reviewTransaction }: ReviewTransactionProps) => {
  const { frequencyLocale } = reviewTransaction;
  let amount: number | null;
  let description: string;
  let name: string;

  if ('normalizedCounterName' in reviewTransaction) {
    amount = reviewTransaction.amount;
    name = reviewTransaction.normalizedCounterName;
    description = reviewTransaction.categoryLocale;
  } else {
    name = reviewTransaction.recipientName;
    amount = -1 * reviewTransaction.amount;
    description = reviewTransaction.description;
  }

  const switchingDate = reviewTransaction.dates?.switchingDate;

  return (
    <div className='review-transaction'>
      <div className='wrapper'>
        <div className='data'>
          <div data-testid='review-transaction-name' className='name'>
            {name}
          </div>
          <div data-testid='review-transaction-info' className='info'>
            {description}
          </div>
          {amount ? (
            <div data-testid='review-transaction-amount' className='amount'>
              {`${amount > 0 ? '+' : '-'}${removeNegativeSign(formatAmount(amount.toString()))} €`}
              {` ${frequencyLocale && frequencyLocale}`}
            </div>
          ) : null}
        </div>
        <div className='extra-info'>
          {switchingDate && (
            <div data-testid='review-transaction-switching-date' className='date'>
              {formatDate(switchingDate)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReviewTransaction;
