import React, { useEffect } from 'react';
import { ReactComponent as CancelIcon } from '../../../assets/cancel.svg';
import './index.scss';

interface PopupProps {
  onClose: () => void;
  children?: React.ReactNode;
  appearance?: 'centered' | 'bottom' | 'full';
}

const Modal = ({ onClose, children, appearance = 'centered' }: PopupProps) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'auto';
    };
  }, []);

  return (
    <div className='modal-wrapper' data-testid='modal-wrapper'>
      <div className='modal-overlay'>
        <div className={`modal ${appearance}`} data-testid='modal'>
          <div className='modal-header'>
            <CancelIcon className='modal-close' onClick={onClose} data-testid='modal-close-icon' />
          </div>
          <div className='modal-content'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
