import React from 'react';
import './index.scss';

import { ReactComponent as CloseIcon } from '../../../assets/close.svg';

interface AlertCardProps {
  children?: React.ReactNode;
  close: () => void;
  isOpen?: boolean;
}

const AlertCard = ({ children, close, isOpen }: AlertCardProps) =>
  isOpen ? (
    <div className='alert-card'>
      <CloseIcon onClick={close} className='close' data-testid='alert-card-close-icon-id' />{' '}
      {children}
    </div>
  ) : null;

export default AlertCard;
