import React from 'react';
import { formatDate } from '../../../../../../utils/helper';

import './index.scss';
import { NotificationModel } from '../../../../../../types/Overview/NotificationModel';
import { StandingOrderModel } from '../../../../../../types/Overview/StandingOrderModel';
import { ReactComponent as CarretIcon } from '../../../../../../assets/carret.svg';
import { useKwsState } from '../../../../../../contexts/KwsStateContext/KwsStateContext';
import { ItemType } from '../../../../../../types/Overview/Common';

interface TransactionProps {
  data: NotificationModel | StandingOrderModel;
}

const Transaction = ({ data }: TransactionProps) => {
  const { setStandingOrderToProcess, setNotificationToProcess } = useKwsState();

  const handleClick = () => {
    if (data.itemType === ItemType.NOTIFICATION)
      return setNotificationToProcess({
        pageType: 'EDIT',
        id: data.id,
      });

    return setStandingOrderToProcess({
      pageType: 'EDIT',
      id: data.id,
    });
  };

  return (
    <div
      className='transaction-details'
      onClick={handleClick}
      data-testid='switching-date-transaction-details'
    >
      <div className='details'>
        <div className='information'>
          <div className='name line-clamp-1'>
            {'normalizedCounterName' in data ? data.normalizedCounterName : data.recipientName}
          </div>
          <div className='category'>
            {'normalizedCounterName' in data ? data.categoryLocale : data.description}
          </div>
        </div>

        <div className='flex-wrapper'>
          <div className='date'>{formatDate(data.dates?.switchingDate)}</div>
          <div className='carret-color'>
            <CarretIcon className='rotate-90' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transaction;
