import React, { useEffect, useMemo, useState } from 'react';
import { TooltipStateContext } from './TooltipStateContext';
import { ITooltipStateProvider, TooltipStateEnum } from './Tooltip.types';
import { useKwsState } from '../KwsStateContext/KwsStateContext';

const TooltipStateProvider: React.FC<Partial<ITooltipStateProvider>> = ({ children }) => {
  const [tooltipState, setTooltipState] = useState<TooltipStateEnum | undefined>();
  const {
    kwsState,
    notificationToProcess,
    standingOrderToProcess,
    switchingDateToProcess,
    accountToProcess,
  } = useKwsState();

  useEffect(() => {
    const tooltipElements = document.querySelectorAll('.tooltip-wrapper');

    if (!tooltipElements.length) {
      setTooltipState(undefined);
    }
  }, [
    notificationToProcess,
    standingOrderToProcess,
    switchingDateToProcess,
    accountToProcess,
    kwsState?.state,
  ]);

  const initialProvider: ITooltipStateProvider = useMemo(
    () => ({ tooltipState, setTooltipState }),
    [tooltipState],
  );

  return (
    <TooltipStateContext.Provider value={initialProvider}>{children}</TooltipStateContext.Provider>
  );
};

export default TooltipStateProvider;
